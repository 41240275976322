import React, { Component } from 'react'
import { WhiteSpace, Button, Modal, PickerView, Toast, Carousel } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css';
import Tabbar from '../../components/tabbar'
import cookie from 'react-cookies'
import { Link } from 'react-router-dom'
import { req_updateUser, req_findAppAllSchoolConfig, req_findAppModule, req_findAppArticleType, req_findAppArticle } from '../../api/index'
import { req_searchPerson } from '../../api/my'
import IP from '../../config/config'
import getQueryString from '../../untils/sha1'
import moment from 'moment'
import './index.css'

const alert = Modal.alert
class index extends Component {

    state = {
        schoolArray: [], // 所有学校列表
        gridData: [], // 模块列表
        articleData: [], // 文章内容
        schoolVisible: cookie.load('data').school_id ? false : true, // 是否显示选择学校弹框
        school_id: [cookie.load('data').school_id] || "", // 学校id
        status: '', // 
        school_name: '', // 学校名称
        data: [],
        imgHeight: 200,
        prompt: false, // 选完学校时，提示是否去实名
    }

    componentWillMount = () => {
        this.getSchools()
        if (this.state.schoolVisible) {
            cookie.load('schoolID') ? this.updateUser(cookie.load('schoolID')) : Toast.info('学校选定后，不能修改，请慎重选择')
        } else {
            this.getContent()
        }
    }

    // 修改SchoolID
    updateUser = async schoolid => {
        const res = await req_updateUser({ school_id: schoolid })
        if (res.code === 20000) {
            this.getContent();
            // this.getUser()
            this.setState({
                schoolVisible: false,
                school_id: this.state.school_id[0],
                prompt: true,
            })
        } else {
            Toast.fail(res.message);
        }

    }

    // 获取用户信息
    getUser = () => {
        req_searchPerson({ userId: cookie.load("userId") }).then(res => {
            if (res.code === 20000) {
                cookie.save('data', res.data)
                this.setState({
                    user: res.data,
                    phoneNum: res.data.user_phone,
                    buildingId: res.data.area_id ? res.data.area_id.split(",").map(x => Number(x)) : [],
                    room: res.data.room,
                    buildingName: res.data.area_name,
                })
                if (getQueryString('code') !== null && !res.data.identity_number && window.aa) {
                    this.setState({
                        prompt: true
                    })
                }
            } else {
                Toast.fail(res.message)
            }

        })
    }

    // 获取所有学校
    getSchools = () => {
        req_findAppAllSchoolConfig().then(value => {
            if (value.code === 20000) {
                let schoolName = ''
                let nameArray = value.data.map(item => {
                    if (Number(item.school_id) === Number(this.state.school_id)) {
                        schoolName = item.school_name
                    }
                    return {
                        label: item.school_name,
                        value: item.school_id
                    }
                });
                this.setState({
                    schoolArray: [nameArray],
                    school_name: schoolName
                })
            }
        })
    }

    // 获取首页内容
    getContent = () => {
        req_findAppArticleType().then(v => {
            if (v.code === 20000) {
                this.setState({
                    title: v.data,
                    status: v.data && v.data[0] && v.data[0].id
                }, () => {
                    this.findAppArticle(this.state.status)
                })
            }
        })
        req_findAppModule().then(res => {
            if (res.data) {
                let list = res.data.list.map(v => {
                    return {
                        id: v.id,
                        title: v.module_name,
                        url: v.module_url,
                        icon: IP.host + v.module_image_url
                    }
                });
                this.setState({
                    gridData: list
                })
            }
        })
        //大范围用
        req_findAppArticle().then(value => {
            //轮播图
            if (value.code === 20000) {
                if (value.data) {
                    this.setState({
                        data: value.data.filter((v) => {
                            v.color = ''
                            return v.flag === 1;
                        }),
                        anno: value.data.filter((v) => {
                            return v.flag === 2;
                        }),

                    })
                }
            } else {
                Toast.fail(value.message)
            }
        })
        this.getUser()
    }

    //  根据文章类型查找文章
    findAppArticle = (type) => {
        req_findAppArticle({ typeId: type }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    articleData: res.data.filter(x => x.flag === 0)
                })
            } else {
                Toast.fail(res.message)
            }
        })
    }

    render() {
        const { school_name, gridData, articleData, schoolVisible, prompt } = this.state
        return (
            <div className='home' >
                <div className='header' >
                    <div className="address" >
                        {school_name}
                    </div>
                </div>
                { /* 轮播图 */}
                <Carousel
                    className="spaceCarousel"
                    cellSpacing={20}
                    slideWidth={0.94}
                    dotActiveStyle={{ background: '#ffffff' }}
                    dotStyle={{ background: 'rgb(255,255,255,0.5)' }}
                    infinite={true}
                    autoplay={true}
                    afterChange={index => this.setState({ slideIndex: index })} >
                    {this.state.data.map((val, index) =>
                        <Link key={index}
                            to={`/detail/${val.id}`}
                            style={
                                {
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: this.state.imgHeight
                                }
                            } >
                            <img id={`img${val.id}`}
                                src={IP.host + val.article_url}
                                alt=""
                                style={
                                    { width: '100%', zIndex: 5, height: '100%', borderRadius: 5 }}
                                onLoad={
                                    () => {
                                        this.setState({
                                            imgHeight: 177
                                        })
                                    }
                                }
                            />
                        </Link>
                    )
                    }
                </Carousel>
                { /* 模块 */}
                <div className="module" >
                    <ul>
                        {/* {console.log(gridData,'ddddddddddddddddddddddd')} */}
                        {
                            gridData.length > 0 ? gridData.map(x => {
                                return (
                                    <li key={x.id}
                                        onClick={
                                            () => {
                                                if ((/http:\/\//.test(x.url)) || (/https:\/\//.test(x.url))) return window.location.href = `${x.url}${x.url.includes('?') ? '&' : '?'}sessionId=${cookie.load('sessionId')}`
                                                this.props.history.push(x.url)
                                            }
                                        } >
                                        <div className="img-size"><img src={x.icon} className='moduleIcon' alt="" /></div>
                                        <span> {x.title} </span>
                                    </li>
                                )
                            }) : ''
                        }
                        <li onClick={() => this.props.history.push("/mima")}>
                            <div className="img-size"><img src={require('../../image/mima.png')} className='moduleIcon' alt="" /></div>
                            <span>校园网密码修改</span>
                        </li>
                        {
                            cookie.load('data').school_id === 41 && (<li onClick={() => {
                                // window.open('http://172.16.100.21')
                                this.props.history.push('/schoolout/0')
                            }}>
                                <div className="img-size"><img src={require('../../image/download.png')} className='moduleIcon' alt="" /></div>
                                <span>校园网下线</span>
                            </li>)
                        }
                        {
                            cookie.load('data').school_id === 40 && (<li onClick={() => {
                                // window.open('http://10.100.200.3')
                                this.props.history.push('/schoolout/1')
                            }}>
                                <div className="img-size"><img src={require('../../image/download.png')} className='moduleIcon' alt="" /></div>
                                <span>校园网下线</span>
                            </li>)
                        }
                    </ul>
                </div>
                { /* 校园活动 */}
                <WhiteSpace size="lg" style={{ background: '#f8f9fa' }} ></WhiteSpace>
                <div className="active" >
                    <div className="clearfloat activeTop" >
                        <div className="left activeLeft" >
                            <span className="left" ></span>
                            <h1 > 校园活动 </h1>
                        </div>
                        <div className="right" >
                            <span> 系统已更新 < i > {articleData.length} </i>篇</span>
                        </div>
                    </div>
                    {articleData.length > 0 ? articleData.map(x => {
                        return (
                            <Link to={`/detail/${x.id}`}
                                key={x.id} >
                                <div className='content clearfloat'
                                    style={
                                        { paddingTop: 0 }}
                                    key={x.id} >
                                    <div className='left title' >
                                        <h3 > {x.article_title} </h3>
                                        <p>
                                            <span> {moment(x.publish_time).format("YYYY-MM-DD HH:mm")} </span>
                                        </p>
                                    </div>
                                    <div className="right titleImg" >
                                        {
                                            x.article_url ? <img src={IP.host + x.article_url} alt="logo" /> : ''
                                        }
                                    </div>
                                </div>
                            </Link>)
                    }) : '暂无数据'}
                </div>
                <div style={{ textAlign: 'center', background: '#f5f5f5', color: '#cdcdcd', fontSize: '0.24rem', paddingTop: '.5rem', paddingBottom: '.2rem' }} >已显示全部内容 </div>
                { /* 导航 */}
                <Tabbar {...this.props} > </Tabbar>
                { /* 选择学校 */}
                <Modal visible={schoolVisible} title="请选择学校" >
                    <div style={{ margin: "30% auto" }} >
                        <PickerView data={this.state.schoolArray}
                            cascade={false}
                            value={this.state.school_id}
                            onChange={
                                (v) => {
                                    this.setState({
                                        school_id: v,
                                    })
                                }
                            }
                        />
                    </div>
                    <Button type="primary"
                        icon="check-circle-o"
                        style={{ width: "100%", position: "fixed", bottom: "0px" }} onClick={
                            () => {
                                alert('选择学校', <div style={{ marginBottom: 15 }}>
                                    <div style={{ marginBottom: 5 }}>当前选择学校: <span style={{ color: '#FF7256', fontSize: 15, fontWeight: 'bold' }}>{this.state.schoolArray[0].find(x => x.value === Number(this.state.school_id.join())).label}</span></div>
                                    <div>
                                        一旦确定,将无法修改学校,请慎重选择!!!
                                    </div>
                                </div>, [
                                    { text: '取消' },
                                    {
                                        text: '确定', color: '#222222',
                                        onPress: () => {
                                            if (!`${this.state.school_id}`) {
                                                this.setState({
                                                    school_id: this.state.schoolArray[0][0].value,
                                                    school_name: this.state.schoolArray[0].find(x => Number(x.value) === Number(this.state.schoolArray[0][0].value)).label
                                                })
                                            } else {
                                                this.setState({
                                                    school_name: this.state.schoolArray[0].find(x => Number(x.value) === Number(this.state.school_id[0])).label
                                                })
                                            }
                                            req_updateUser({ school_id: `${this.state.school_id}` }).then(res => {
                                                if (res.code === 20000) {
                                                    this.getContent();
                                                    this.setState({
                                                        schoolVisible: false,
                                                        school_id: this.state.school_id[0],
                                                        prompt: true,
                                                    })
                                                } else {
                                                    Toast.fail(res.message);
                                                }
                                            })
                                        }
                                    },
                                ])

                            }
                        } > 确定 </Button>
                </Modal>
                {/* 实名提示 */}
                <Modal visible={prompt} title={<span className="modalTitle">是否实名</span>} style={{ width: '85%' }} transparent={true} closable={true} onClose={() => { this.setState({ payPassWord: false }) }}>
                    <p style={{ padding: '10px 0 20px', fontSize: '16px' }}>您还未实名，是否去实名</p>
                    <div className='flex'>
                        <div className="colse" onClick={() => {
                            this.setState({
                                prompt: false
                            })
                        }}>取消</div>
                        <div className="ok" onClick={() => {
                            this.props.history.push('/realName')
                        }}>去实名</div>
                    </div>
                </Modal>
            </div>)
    }
}
export default index;