import React, { Component } from 'react'
import { Button, Toast, Picker, List } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css';
import mod from './../../image/modal.png'
import cookie from 'react-cookies'
import { senSmsCode,offline } from '../../api/index'
import './schoolOut.css'
const { Item } = List
class index extends Component {

  state = {
    data: [
      {
        url: 'http://172.16.100.21', //41
      },
      {
        url: 'http://10.100.200.3' //40
      },
      {
        url: 'http://172.16.100.21' //42
      }
    ],
    userDate: {},
    accountNum: '',        // 账号
    code: '',            // 手机验证码
    flag: [0],
  }

  componentWillMount = () => {
    const data = cookie.load('data')
    this.setState({
      userDate: data
    })
    const id = this.props.match.params.id
    const that = this
    if (data.school_id === 40) {
      setTimeout(function () {
        const { data } = that.state
        const url = data[id].url
        window.open(url)
      }, 1000)
    }

  }
  // 提交
  submit = () => {
    Toast.loading('提交中...', 0)
    const { accountNum, code, flag } = this.state
    if (accountNum.length !== 14) {
      Toast.fail('账号错误或非常规账号,请去校园网中心人工修改校园网账号密码!!')
      return
    }
    if (!code) {
      Toast.fail('请输入验证码');
      return;
    }
    let userEntity = {
      campusAccount: accountNum,
      checkCode: code,
    }
    // console.log(userEntity)
    offline(userEntity).then(res => {
      Toast.info(res.message)
      if (res.code === 20000) {
        this.props.history.push('/')
        Toast.hide()
      }
    })
  }
  render() {
    const { userDate, accountNum } = this.state
    return (
      <div style={{ minHeight: '100vh' }}>
        {
          userDate.school_id === 40 ? <div style={{ height: '100vh', padding: '0px 20px', background: 'rgba(0, 0, 0,0.8)' }}>
            <img src={mod} style={{ width: '100%' }}></img>
          </div> : <div className="schoolOut">
              <div className="realName" style={{ paddingTop: 20 }}>
                <div className="flexPad">
                  <div><i>*</i>校园网账号</div>
                  <input type="text" placeholder={accountNum ? accountNum : '请输入校园网账号'} onChange={(v) => {
                    this.setState({
                      accountNum: v.target.value,
                    })
                  }} />
                </div>
                <div className="flexPad">
                  <div><i>*</i>验证码</div>
                  {this.state.isClick && <input type="number" placeholder='请输入验证码' onChange={(v) => {
                    this.setState({
                      code: v.target.value,
                    })
                  }} className="modalCode" />}
                  {!this.state.isClick && <div className="buttonStyle1" onClick={() => {
                    if (!this.state.accountNum || this.state.accountNum.length !== 14) {
                      Toast.fail('账号错误或非常规账号,请去校园网中心人工修改校园网账号密码!!')
                      return false;
                    }
                    senSmsCode({ userId: this.state.accountNum }).then(res => {
                      Toast.info(res.message)
                      if (res.code === 20000) {
                        this.setState({
                          isClick: true,
                        })
                      }
                    })
                  }}>获取验证码</div>}
                </div>

                {/* {
          cookie.load('data').school_id == 41 ? (<div className="flexPad">
            <div><i>*</i>是否在黄淮学院</div>
            <Picker data={this.state.stateType} cols={1} value={flag} onChange={(flag)=>{
              this.setState({
                flag
              })
            }}>
              <Item arrow="horizontal"  className="mima"></Item>
            </Picker>
          </div>) : ('')
        } */}

                <Button style={{ background: '#fc0', color: '#222', marginTop: '.8rem' }} onClick={() => this.submit()}>提交</Button>
              </div>
            </div>
        }
      </div>
    )
  }
}
export default index;