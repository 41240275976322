import React, { Component } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom'
import RouteMap from './route/index'
import { req_getWXuserInfo } from './api/my'
import getQueryString from './untils/sha1'
import IP from './config/config'
import cookie from 'react-cookies'
import { Modal, Toast } from 'antd-mobile';

const alert = Modal.alert;

class App extends Component {
  state = {
    schoolArray: [],
    isShow: false,
  }

  componentDidMount = () => {
    this.getCode()
  }

  getCode = () => {
    Toast.loading('加载中...', 0)
    // 如果getQueryString一个不存在的参数，返回null
    if (String(getQueryString("orderId")) !== "null") cookie.save("orderId", getQueryString("orderId")) //传递给报修模块评价使用
    if (String(getQueryString("status")) !== "null") cookie.save("status", getQueryString("status")) //传递给报修模块待接单界面使用，便于接单
    if (String(getQueryString("schoolID")) !== 'null') cookie.save('schoolID', getQueryString('schoolID')); // 获取传递来的学校编号
    if (String(getQueryString("wenJuan")) !== 'null') cookie.save('wenJuan', getQueryString('wenJuan')) //根据标识跳转到问卷调查,此时先写死
    if (String(getQueryString("repair")) !== 'null') cookie.save('repair', getQueryString('repair')) //根据标识跳转到报修模块,此时先写死
    if (String(getQueryString("media")) !== 'null') cookie.save('media', getQueryString('media')) //根据标识跳转到多媒体模块,此时先写死
    if (String(getQueryString("roomid")) !== 'null') cookie.save('roomid', getQueryString('roomid')) // 扫码标识,携带房间号
    if (String(getQueryString("mediaRepair")) !== 'null') cookie.save('mediaRepair', getQueryString('mediaRepair')) // 微信多媒体报修消息跳转
    if (String(getQueryString("serect")) !== 'null') cookie.save('serect', getQueryString('serect')) // 微信多媒体报修消息跳转

    if (String(getQueryString("openAccount")) !== 'null') cookie.save('openAccount', getQueryString('serect')) // 办理开户审核消息跳转
    if (getQueryString('code')) {
      var code = getQueryString('code')
      req_getWXuserInfo({ code: code }).then(res => {
        Toast.hide();
        if (res.code === 20000) {
          cookie.save("data", res.data)
          cookie.save("userId", res.data.user_id)
          cookie.save("sessionId", res.data.sessionId)
          // 这是删除电影票中的cookie值
          cookie.remove("moive")
          // 删除预约办卡的cookie值
          cookie.remove("card")
          
          // 扫码跳转到预约排号
          if(getQueryString('state') === '1'){
            return window.location.href = `http://fxxy.xctdmall.cn:6688?sessionId=${cookie.load("sessionId")}&subscribe=1`
          }

          // 此时进行问卷跳转
          // 判断有没有选择学校
          if (cookie.load('wenJuan')) {
            cookie.remove('wenJuan')
            if (!res.data.school_id) return alert("注意", <div style={{ marginBottom: 10 }}>请返回关注公众号蜂行校园,并选择自己的学校,否则可能无法接受到中奖信息</div>, [{ text: '确定' }])
            return window.location.href = 'http://fxxy.xctdmall.cn:3287'
          }
          if (cookie.load('repair')) {
            cookie.remove('repair')
            if (!res.data.school_id) return alert("注意", <div style={{ marginBottom: 10 }}>请返回关注公众号蜂行校园,并选择自己的学校,否则无法完成报修</div>, [{ text: '确定' }])
            return window.location.href = `http://fxxy.xctdmall.cn:6321?sessionId=${cookie.load("sessionId")}`
          }
          if (cookie.load('media')) {
            cookie.remove('media')
            if (!res.data.school_id) return alert("注意", <div style={{ marginBottom: 10 }}>请返回关注公众号蜂行校园,并选择自己的学校,否则无法完成多媒体报修</div>, [{ text: '确定' }])
            return window.location.href = `http://fxxy.xctdmall.cn:5566?sessionId=${cookie.load("sessionId")}`
          }
          if (cookie.load('roomid')) {
            if (!res.data.school_id) return alert("注意", <div style={{ marginBottom: 10 }}>请返回关注公众号蜂行校园,并选择自己的学校,否则无法完成多媒体扫码报修</div>, [{ text: '确定' }])
            return window.location.href = `http://fxxy.xctdmall.cn:5566?sessionId=${cookie.load("sessionId")}`
          }
          if (cookie.load('mediaRepair')) {
            return window.location.href = `http://fxxy.xctdmall.cn:6677?sessionId=${cookie.load("sessionId")}`
          }
          if (cookie.load('serect')) {
            if (!res.data.school_id) return alert("注意", <div style={{ marginBottom: 10 }}>请返回关注公众号蜂行校园,并选择自己的学校,否则无法完成多媒体扫码报修</div>, [{ text: '确定' }])
            return window.location.href = `http://fxxy.xctdmall.cn:7777?sessionId=${cookie.load("sessionId")}`
          }
          if (cookie.load('openAccount')) {
            if (!res.data.school_id) return alert("注意", <div style={{ marginBottom: 10 }}>请返回关注公众号蜂行校园,并选择自己的学校,否则无法完成多媒体扫码报修</div>, [{ text: '确定' }])
            return window.location.href = `http://fxxy.xctdmall.cn:6421?sessionId=${cookie.load("sessionId")+'&openAccount=100'}`
          }
          this.setState({
            isShow: true
          })
          // state代表着跳转地址，如果不为空则携带sessionID跳转到指定界面
          if (String(getQueryString('state')) !== "null") {
            window.location.href = `${getQueryString('state')}${getQueryString('state').includes('?') ? '&' : '?'}sessionId=${res.data.sessionId}`
          }
        } else {
          // Toast.fail(res.message)
          window.location.href = `http://open.weixin.qq.com/connect/oauth2/authorize?appid=${IP.Appid}&redirect_uri=${encodeURIComponent(IP.wxredirect)}&response_type=code&scope=snsapi_userinfo&state=${getQueryString('redirectUrl')}#wechat_redirect`;
        }
      })
    }
    else {
      window.location.href = `http://open.weixin.qq.com/connect/oauth2/authorize?appid=${IP.Appid}&redirect_uri=${encodeURIComponent(IP.wxredirect)}&response_type=code&scope=snsapi_userinfo&state=${getQueryString('redirectUrl')}#wechat_redirect`;
    }
  }

  render() {
    return (
      <BrowserRouter>
        {this.state.isShow ? <RouteMap /> : ''}
      </BrowserRouter>
    )
  }
}

export default App;
