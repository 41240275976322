import React, { Component } from 'react'
import { TextareaItem, Button, WingBlank, Toast } from 'antd-mobile';
import './index.css'
import { addShadow } from '../../api/my';
export default class recharge extends Component {
    state = {
        data: [], // 充值记录
    }
    
    render() {
        const { description } = this.state
        return (
            <div>
                <div className="moive-main">
                    <div className="moive-header">发布电影影讯</div>
                    <div className="moive-content">
                        <TextareaItem
                            rows={15}
                            onChange={(e)=>{
                                this.setState({
                                    description:e
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="moive-button">
                    <WingBlank size="lg"><Button type="primary" onClick={()=>{
                        addShadow({description}).then(res=>{
                            if(res.code!==20000) return Toast.fail(res.message)
                            Toast.success(res.message)
                            this.props.history.goBack()
                        })
                    }}>发布</Button></WingBlank>
                </div>
            </div>
        )
    }
}
