import React, { Component } from 'react';
import { req_findArticleById } from '../../api/index';
import { WingBlank} from 'antd-mobile';

export default class Details extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data:{}
        }
    }
    componentWillMount() {
        this.first();
    }

    first=()=>{
        req_findArticleById({id:this.props.match.params.id}).then(res=>{
            this.setState({
                data:res.data
            })
        })
    }

    render() {
        return (
            <div className='detail'>
                <WingBlank size="sm">
                    {/* <div style={{width:"100%",textAlign:"center"}}>
                        <div style={{padding:"10px 0px",fontSize:'16px',fontWeight:'bold'}}>{this.state.data.article_title}</div>
                    </div> */}
                    <div style={{width:"100%",padding:'5px',wordBreak:"break-all"}} dangerouslySetInnerHTML={{__html: this.state.data.article_content}}>
                    </div>
                </WingBlank>
            </div>
        )
    }
}


