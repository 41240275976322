import React, { Component } from 'react'
import Tabbar from '../../components/tabbar'

export default class index extends Component {
    render() {
        return (
            <div>
                购物车
                <Tabbar {...this.props}></Tabbar>
            </div>
        )
    }
}
