import React, { Component } from 'react'
import './index.css'
import { Button, Toast, Picker } from 'antd-mobile';
import { req_walletRecharge } from '../../api/my'
// import getQueryString from '../../untils/sha1'
import cookie from 'react-cookies'
import { req_findWalletByUserId, req_appFindSaleMoney } from '../../api/my'

export default class pay extends Component {
    state = {
        payMoney: [],
        saleMoneyConfigId: '',   // 充值金额
        walletType: Number(this.props.match.params.id),      //充值类型0热水  、1校园网
        clickIndex: 0,  // 选中的充值金额
        user: cookie.load('data'),
        water_wallet: {},    // 热水
        net_wallet: {},      // 校园网
        visible: false, //picker框是否展示
        pickerValue: [0], //picker框默认展示值
        payData:{} // 返回的翼支付数据
    }
    componentDidMount = () => {
        this.getMoney()
        this.getPayMoney()
    }

    // 获取热水、校园网余额（0热水、1校园网）
    getMoney = () => {
        const { user, walletType } = this.state
        let obj = {
            schoolId: user.school_id,
            userId: user.user_id,
            walletType
        }
        req_findWalletByUserId(obj).then(res => {
            if (res.code === 20000) {
                res.data.map(x => {
                    if (x.wallet_type === 0) {
                        this.setState({
                            water_wallet: x
                        })
                    } else {
                        this.setState({
                            net_wallet: x
                        })
                    }
                    return x;
                })
            } else {
                Toast.fail(res.message)
            }
        })
    }

    // 获取充值金额
    getPayMoney = () => {
        const { user, walletType } = this.state
        req_appFindSaleMoney({ schoolId: user.school_id, type: walletType }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    payMoney: res.data
                })
            } else {
                Toast.fail(res.message)
            }
        })
    }

    moey = (v, num) => {
        this.setState({
            saleMoneyConfigId: v.sale_money_config_id,
            clickIndex: num
        })
    }

    // 调取微信支付
    pay = (data) => {
        let that = this
        function onBridgeReady() {
            window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId": data.appId,     //公众号名称，由商户传入     
                "timeStamp": data.timeStamp, //时间戳，自1970年以来的秒数     
                "nonceStr": data.nonceStr, //随机串     
                "package": data.package,
                "signType": "MD5", //微信签名方式：     
                "paySign": data.paySign //微信签名 
            },
                function (res) {
                    if (res.err_msg === "get_brand_wcpay_request:ok") {
                        Toast.success("微信支付成功", 1)
                        that.getMoney()
                    } else {
                        console.log(res, "失败res")
                    }
                }
            );
        }
        if (typeof WeixinJSBridge === "undefined") {
            if (window.document.addEventListener) {
                window.document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (window.document.attachEvent) {
                window.document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                window.document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
        } else {
            onBridgeReady();
        }
    }

    // 调用翼支付
    // yiPay = (data) => {
    //     const { user } = this.state
    //     var xhr = new XMLHttpRequest(); // IE8/9需用window.XDomainRequest兼容
    //     // 前端设置是否带cookie
    //     xhr.withCredentials = true;
    //     xhr.open('post', 'https://mapi.bestpay.com.cn/mapi/form/cashier/H5/pay', true);
    //     xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    //     xhr.onreadystatechange = function () {
    //         if (xhr.readyState === 4 && xhr.status === 200) {
    //             alert(xhr.responseText);
    //         }
    //     };
    //     axios({
    //         method: 'post',
    //         url: 'https://mapi.bestpay.com.cn/mapi/form/cashier/H5/pay',
    //         headers: {
    //             'Access-Control-Allow-Origin': 'http://fxxy.xctdmall.cn',
    //             'Access-Control-Allow-Credentials': true
    //         },
    //         data: {
    //             ...data,
    //             signType: 'CA',
    //             platform: 'H5_4.0',
    //             tradeType: 'acquiring',
    //             tradeDesc: data.subject,
    //             accountId: user.user_phone,
    //             merchantFrontUrl: 'http://fxxy.xctdmall.cn'
    //         }
    //     })
    //         .then(function (response) {
    //             console.log(response);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });

    // }

    render() {
        const { payMoney, clickIndex, user, walletType, water_wallet, net_wallet, visible, pickerValue, payData } = this.state
        const pickData = [
            {
                label: '微信支付',
                value: 0
            },
            // {
            //     label: '翼支付',
            //     value: 2
            // }
        ]
        return (
            <div className='pay'>
                <form id="payform" action="https://mapi.bestpay.com.cn/mapi/form/cashier/H5/pay" method="post" style={{display:'none'}}>
                    <input type='text' name='merchantNo' value={payData.merchantNo} />
                    <input type='text' name='institutionCode' value={payData.institutionCode} />
                    <input type='text' name='institutionType' value={payData.institutionType} />
                    <input type='text' name='signType' value={payData.signType} />
                    <input type='text' name='sign' value={payData.sign} />
                    <input type='text' name='platform' value={payData.platform} />
                    <input type='text' name='tradeType' value={payData.tradeType} />
                    <input type='text' name='outTradeNo' value={payData.outTradeNo} />
                    <input type='text' name='tradeNo' value={payData.tradeNo} />
                    <input type='text' name='tradeAmt' value={payData.tradeAmt} />
                    <input type='text' name='tradeDesc' value={payData.tradeDesc} />
                    <input type='text' name='merchantFrontUrl' value={payData.merchantFrontUrl} />
                    <input type="submit" value="OK" id="submit" />
                </form>
                <div className="header">
                    <img src={require('../../image/充值背景图.png')} alt="" style={{ width: '100%' }} />
                    <div className="headerTop">
                        <div className="top">
                            <span>钱包余额(单位:元)</span>
                            <span onClick={() => {
                                this.props.history.push('/recharge')
                            }}>充值明细 ></span>
                        </div>
                        <div className="middle">
                            <h1>{walletType === 0 ? water_wallet.wallet_total_money : net_wallet.wallet_total_money}</h1>
                            <span>{walletType === 0 ? '热水钱包余额' : '校园网钱包'}</span>
                        </div>
                        {walletType === 0 ? <div className="bottom">
                            <span className="">热水本金: {water_wallet.wallet_principal}</span>
                            <span className="">赠送金额: {water_wallet.wallet_gift_amount}</span>
                        </div> : <div className="bottom">
                                <span className="">校园网本金: {net_wallet.wallet_principal}</span>
                                <span className="">赠送金额: {net_wallet.wallet_gift_amount}</span>
                            </div>}
                    </div>
                </div>
                {/* 充值 */}
                <div className="money">
                    <div className="clearfloat activeTop">
                        <div className="left activeLeft">
                            <span className="left"></span>
                            <h1>充值金额</h1>
                        </div>
                        <div className="right" onClick={() => {
                            this.setState({
                                visible: true
                            })
                        }}>
                            <span>{pickData.find(x => x.value === Number(pickerValue.join())).label}<i> > </i></span>
                        </div>
                    </div>
                    <div className="payMoney">

                        {payMoney.map((v, index) => {
                            return (
                                <div key={index} onClick={() => this.moey(v, index)} style={clickIndex === index ? { background: '#fed509' } : { background: '#fff' }}>
                                    <p>{v.face_value}元</p>
                                    <span>售价:{v.sale_money}元</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{ position: 'fixed', bottom: 20, width: '100%', marginLeft: '-.3rem', padding: '0 0.3rem' }}>
                    <Button style={{ background: '#ffdb26' }} onClick={() => {
                        let obj = {
                            saleMoneyConfigId: this.state.saleMoneyConfigId || this.state.payMoney[0].sale_money_config_id,
                            walletType: this.state.walletType,
                            schoolId: user.school_id,
                            userId: user.user_id,
                            payType: pickerValue.join()
                        }
                        req_walletRecharge(obj).then(res => {
                            if (res.code === 20000) {
                                pickerValue.join() === '0' && this.pay(res.data)
                                pickerValue.join() === '2' && this.setState({
                                    payData:JSON.parse(res.data)
                                },()=>{
                                    document.getElementById("submit").click()
                                })
                            } else {
                                Toast.fail(res.message)
                            }
                        })
                    }}>确认充值</Button>
                </div>
                <Picker
                    visible={visible}
                    data={pickData}
                    value={pickerValue}
                    cols={1}
                    onOk={(e) => this.setState({
                        pickerValue: e,
                        visible: false
                    })}
                    onDismiss={() => this.setState({ visible: false })}
                ></Picker>
            </div>
        )
    }
}
