import axios from 'axios';
import { Toast } from 'antd-mobile';
import qs from 'qs';
import IP from './config';
import cookie from 'react-cookies'

let baseUrl = IP.host;
let baseUrl2 = IP.host2;
// let token = '';
axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000000000;
if (process.env.NODE_ENV === 'development') {
  baseUrl = IP.host;
} else {
  baseUrl = IP.host;
}

if (process.env.NODE_ENV === 'development') {
  baseUrl2 = IP.host2;
} else {
  baseUrl2 = IP.host2;
}

const instance = axios.create({
  // 当创建实例的时候配置默认配置
  xsrfCookieName: 'xsrf-token',
  baseURL: baseUrl,
  timeout: 30000000000,
  responseType: 'json',
});

const instance2 = axios.create({
  // 当创建实例的时候配置默认配置
  xsrfCookieName: 'xsrf-token',
  baseURL: baseUrl2,
  timeout: 30000000000,
  responseType: 'json',
});

instance.interceptors.request.use({
  success(config) {
    // 在发送请求之前做某事，比如加一个loading
    return config;
  },
  error: error => {
    // 请求错误时做些事
    // Toast.destroy();
    return Promise.reject(error);
  },
});

instance2.interceptors.request.use({
  success(config) {
    // 在发送请求之前做某事，比如加一个loading
    return config;
  },
  error: error => {
    // 请求错误时做些事
    // Toast.destroy();
    return Promise.reject(error);
  },
});

// instance.interceptors.response.use({
//   success(response) {
//     if (response.code === 20009) {
//       window.location.reload();
//     }

//     if (response.code === 20003) {
//       Toast.info('权限不足！');
//     }

//     if (response.code === 20001) {
//       Toast.info('执行错误');
//     }
//     return Promise.resolve(response.data);
//   },
//   error() {
//     Toast.destroy();
//     Toast.error('系统异常，请联系管理员！', 1);
//   },
// });
instance.interceptors.response.use(
  function (response) {
    if ((response.data.code !== 20000) && (response.data.code !== 20005)) {
      Toast.fail(response.data.message)
    }
    if (response.data.code === 20009) {
      window.location.reload();
    }
    // 3.其他失败，比如校验不通过等
    return Promise.resolve(response);
  },
  err => {
    Toast.fail('系统异常，请联系管理员！');
    return Promise.reject(err);
  }
);

instance2.interceptors.response.use(
  function (response) {
    if ((response.data.code !== 20000) && (response.data.code !== 20005)) {
      Toast.fail(response.data.message)
    }
    if (response.data.code === 20009) {
      window.location.reload();
    }
    // 3.其他失败，比如校验不通过等
    return Promise.resolve(response);
  },
  err => {
    Toast.fail('系统异常，请联系管理员！');
    return Promise.reject(err);
  }
);

export default {
  get(url, param) {
    return new Promise(resolve => {
      instance({
        method: 'get',
        url,
        headers:{'x-auth-token':cookie.load('sessionId')},
        params: param,
      }).then(res => {
        // token =  res.headers['x-auth-token']
        resolve(res.data);
      });
    }).catch(() => {});
  },
  get2(url, param) {
    return new Promise(resolve => {
      instance2({
        method: 'get',
        url,
        headers:{'x-auth-token':cookie.load('sessionId')},
        params: param,
      }).then(res => {
        // token =  res.headers['x-auth-token']
        resolve(res.data);
      });
    }).catch(() => {});
  },
  post(url, param) {
    return new Promise(resolve => {
      instance({
        method: 'post',
        url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded','x-auth-token':cookie.load('sessionId') },
        data: qs.stringify(param),
      })
        .then(res => {
          // token =  res.headers['x-auth-token']

          resolve(res.data);
        })
        .catch(() => {});
    });
  },
  post2(url, param) {
    return new Promise(resolve => {
      instance2({
        method: 'post',
        url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded','x-auth-token':cookie.load('sessionId') },
        data: qs.stringify(param),
      })
        .then(res => {
          // token =  res.headers['x-auth-token']

          resolve(res.data);
        })
        .catch(() => {});
    });
  },
  jsonPost(url, param) {
    return new Promise(resolve => {
      instance({
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token':cookie.load('sessionId')
        },
        data: param,
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(() => {});
    });
  },
};
