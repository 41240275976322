import http from '../config/http';

// 选择学校
export const req_updateUser = (params) => {
    return http.get('/repair/updateUser', params)
}

// 获取所有学校列表
export const req_findAppAllSchoolConfig = (params) => {
    return http.get('/schoolconfig/findAppAllSchoolConfig', params)
}
// 手机端查询文章类型
export const req_findAppArticleType = (params) => {
    return http.get('/repairAppArticleType/findAppArticleType', params)
}

// 手机端查询文章信息(根据文章类型查询)
export const req_findAppArticle = (params) => {
    return http.get('/repairAppArticle/findAppArticle', params)
}

// 手机端查询模块信息
export const req_findAppModule = (params) => {
    return http.get('/repairAppModule/findAppModule', params)
}

// 根据文章id查询一条文章信息
export const req_findArticleById = (params) => {
    return http.get('/repairarticle/findArticleById', params)
}

/**
 * 发布问题
 */
export const saveProblem = params => {
    return http.post('/problem/saveProblem', params)
}

/**
 * 删除问题
 */
export const deleteAppProblem = params => {
    return http.get('/problem/deleteAppProblem', params)
}

/**
 * 上传图片/附件
 */
export const uploadImageReply = params => {
    return http.jsonPost('/problemreply/uploadImageReply', params)
}

/**
 * 查询问答列表
 */
export const findAppProblemReply = params => {
    return http.get('/problem/findAppProblemReply', params)
}

/**
 * 查询问题所有回答
 */
export const findPcRply = params => {
    return http.get('/problemreply/findPcRply', params)
}

/**
 * 查询回复详情
 */
export const findReplyByReplyId = params => {
    return http.get('/problemreply/findReplyByReplyId', params)
}

/**
 * 回复问题
 */
export const saveReply = params => {
    return http.jsonPost('/problemreply/saveReply', params)
}

/**
 * 删除回复
 */
export const deleteAppReply = params => {
    return http.get('/problemreply/deleteAppReply', params)
}

/**
 * 根据登陆用户所属学校查询对应的版块信息
 */
export const findAppProblemType = params => {
    return http.get('/problemtype/findAppProblemType', params)
}



// 获取验证码
export const senSmsCode = params => {
    return http.get2('/campusmodifypassword/senSmsCode', params)
}

// 提交密码修改信息
export const modifypassword = params => {
    return http.post2('/campusmodifypassword/modifypassword', params)
}
// campusmodifypassword/offline
export const offline = params => {
    return http.post2('/campusmodifypassword/offline', params)
}
