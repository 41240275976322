import React, { Component } from 'react'
import { Picker, Button, Toast } from 'antd-mobile'
import cookie from 'react-cookies'
import { req_repairArea, req_fengxingAuthentication, req_updateCode } from '../../api/my'

export default class realName extends Component {
    state = {
        user: cookie.load('data'),
        phoneNum: '',        // 手机号
        name: '',            // 姓名
        card: '',            // 身份证号
        number: '',          // 学号
        count: 0,
        room: '',            // 房间号
        code:'',            // 手机验证码
        codes:'',           // 发送的验证码    
    }

    componentDidMount = async () => {
        const { user } = this.state
        await this.searchArea()
        await this.setState({
            name: user.user_name,
            phoneNum: user.user_phone,
            card: user.identity_number,
            number: user.user_number,
            buildingName: user.area_name,
            buildingId:user.area_id?user.area_id.split(",").map(x=>Number(x)):[],
            room:user.room
        })
    }

    getMaxFloor(treeData) {
        let max = 0
        function each(data, floor) {
            data.forEach(e => {
                if (floor > max) {
                    max = floor
                }
                if (e.children.length > 0) {
                    each(e.children, floor + 1)
                }
            })
        }
        each(treeData, 1)
        return max
    }

    // 点击位置是获取位置信息
    searchArea = () => {
        const { user } = this.state
        return req_repairArea({ school_id: user.school_id }).then(res => {
            let array = res.data.map(x => { return { label: x.area_name, value: x.area_id, parentid: x.parent_id } });
            let newArray = array.map(p => {
                p.children = array.filter(x => x.parentid === p.value)
                return p;
            })
            let secondParentId = newArray.find(y => y.parentid === 0).value
            newArray = newArray.filter(x => x.parentid === secondParentId)
            this.setState({
                building: newArray,
                buildingData: res.data
            }, () => {
                this.setState({
                    count: this.getMaxFloor(newArray)
                })
            })
        })
    }

    // 提交
    submit = () => {
        const { user, name, phoneNum, number, room, buildingId, buildingName, card,code,codes } = this.state
        if (!name) {
            Toast.fail('请输入姓名')
            return;
        }
        if (phoneNum.length !== 11) {
            Toast.fail('请输入正确手机号')
            return
        }
        if(!code){
            Toast.fail('请输入验证码');
            return ;
        }
        if(Number(codes) !== Number(code)){
            Toast.fail('验证码错误，请输入正确验证码');
            return ;
        }
        if (!number) {
            Toast.fail('请输入学号')
            return;
        }
        if (!room) {
            Toast.fail('请输入房间号')
            return;
        }
        if(!/\d{17}\w/.test(card)){
            Toast.fail('请输入正确身份证号')
            return;
        }
        if (!buildingId||buildingId.length===0) {
            Toast.fail('请选择宿舍区域')
            return;
        }
        let userEntity = {
            user_id: user.user_id,
            room,
            area_id: buildingId.join(),
            area_name: buildingName,
            user_phone: phoneNum,
            user_name: name,
            identity_number: card,
            user_number: number
        }
        req_fengxingAuthentication(userEntity).then(res => {
            Toast.info(res.message)
            if (res.code === 20000) {
                this.props.history.push('/my?11')
            }
        })
    }

    render() {
        const { phoneNum, name, card, number, building, buildingName, buildingId, count, room } = this.state
        return (
            <div className="realName">
                <p style={{ padding: '16px 0 24px', fontSize: '14px', color: '#a3a3a3', lineHeight: 1.2 }}>您正在开通-平台服务，注册完成后，请完善个人信息，以便于更准确的核实您的信息</p>
                <div className="flexPad">
                    <div><i>*</i>姓名</div>
                    <input type="text" placeholder={name ? name : '请输入姓名'} onChange={(v) => {
                        this.setState({
                            name: v.target.value,
                        })
                    }} />
                </div>
                <div className="flexPad">
                    <div><i>*</i>学号</div>
                    <input type="number" placeholder={number ? number : '请输入学号'} onChange={(v) => {
                        this.setState({
                            number: v.target.value,
                        })
                    }} />
                </div>
                <div className="flexPad">
                    <div><i>*</i>手机号</div>
                    <input type="number" placeholder={phoneNum ? phoneNum : '请输入手机号'} onChange={(v) => {
                        this.setState({
                            phoneNum: v.target.value,
                        })
                    }} />
                </div>
                <div className="flexPad">
                    <div><i>*</i>验证码</div>
                    {this.state.isClick&&<input type="number" placeholder='请输入验证码' onChange={(v) => {
                        this.setState({
                            code: v.target.value,
                        })
                    }} className="modalCode" />}
                    {!this.state.isClick&&<div className="buttonStyle1" onClick={() => {
                        if (!this.state.phoneNum || this.state.phoneNum.length !== 11) {
                            Toast.fail('输入的新手机号不合法')
                            return false;
                        }
                        req_updateCode({ phoneNumber: this.state.phoneNum, userId: this.state.user.user_id }).then(res => {
                            Toast.info(res.message)
                            if (res.code === 20000) {
                                this.setState({
                                    isClick: true,
                                    codes:res.data
                                })
                            }
                        })
                    }}>获取验证码</div>}
                </div>
                <div className="flexPad">
                    <div><i>*</i>身份证</div>
                    <input type="text" placeholder={card ? card : '请输入身份证号'} onChange={(v) => {
                        this.setState({
                            card: v.target.value,
                        })
                    }} />
                </div>
                <div className="pickerSpan" >
                    <div><i>*</i>宿舍区域</div>
                    <Picker
                        extra="请选择宿舍区域"
                        data={building}
                        value={buildingId}
                        cols={count}
                        onChange={(val) => {
                            this.setState({
                                buildingId: val,
                                buildingName: val.map(x => this.state.buildingData.find(p => p.area_id === x).area_name).join("")
                            })
                        }}
                    >
                        <div>{buildingName?buildingName:<span style={{color:'#D8D8D8'}}>选择宿舍区域</span>}</div>
                    </Picker>
                </div>
                <div className="flexPad">
                    <div><i>*</i>房间号</div>
                    <input type="number" placeholder={room ? room : '请输入房间号'} onChange={(v) => {
                        this.setState({
                            room: v.target.value,
                        })
                    }} />
                </div>
                <Button style={{ background: '#fc0', color: '#222', marginTop: '.8rem' }} onClick={() => this.submit()}>认证</Button>
            </div>
        )
    }
}
