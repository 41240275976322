import React, { Component } from 'react'
import { req_findWalletLogsByUserId } from '../../api/my'
import cookie from 'react-cookies'
import moment from 'moment'
import './index.css';
import { Toast } from 'antd-mobile';

export default class recharge extends Component {
    state = {
        data: [],    // 充值记录
    }
    componentWillMount = () => {
        let user = cookie.load('data')
        req_findWalletLogsByUserId({ userId: user.user_id }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    data: res.data?res.data:[]
                })
            } else {
                Toast.fail(res.message)
            }
        })
    }
    render() {
        const { data } = this.state
        return (
            <div className='recharge'>
                {data.length > 0 ? data.map((v,index) => {
                    return (
                        <div key={index} className='flex '>
                            <div>
                                <p style={{fontSize:'.24rem',color:'#222'}}>{v.content}</p>
                                <span style={{fontSize:'.2rem',color:'#d7d7d7'}}>{moment(v.create_time).format('YYYY-MM-DD hh:mm')}</span>
                            </div>
                            <span style={{fontWeight:'bold',fontSize:'.36rem'}}>{v.type===0?'+'+v.money:'-'+v.money}</span>
                        </div>
                    )
                }) : '暂无充值记录'}
            </div>
        )
    }
}
