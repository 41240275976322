import React, { Component } from 'react';
import { NavBar, Icon, List, TextareaItem, Toast } from 'antd-mobile'
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui';
import { uploadImageReply, saveReply } from '../../api'

class reply extends Component {
    state = {
        urls: [],
        gallery: false,
        demoFiles: [],
        problemDesc: '', // 回答
    }

    componentDidMount(){
        document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
    }
    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 回答问题
    handleQuestion = async () => {
        const { problemDesc, urls, } = this.state;
        if (!problemDesc) return Toast.info('请填写你的回复')
        const req = {
            problem_id: this.props.match.params.id,
            reply_content: problemDesc,
            reply_images: urls && urls.length ? urls.join(',') : '',
        }
        const res = await saveReply(req)
        if (res.code === 20000) {
            Toast.info(res.message)
            this.props.history.goBack()
        }
    }

    render() {
        return (
            <div>
                {this.renderGallery()}
                <NavBar
                    mode="mode"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={<div onClick={() => this.handleQuestion()}>回复</div>}
                >
                    问题回复
                </NavBar>
                <List>
                    <TextareaItem
                        placeholder="请输入问题回复"
                        onChange={(e) => {
                            this.setState({
                                problemDesc: e
                            })
                        }}
                        rows={5}
                        count={200}
                    />
                </List>
                <Cell className='problem_modal_img'>
                    <CellBody>
                        <Uploader
                            title='上传图片（可不填）'
                            maxCount={4}
                            files={this.state.demoFiles}
                            onError={msg => alert(msg)}
                            onChange={(file) => {
                                let newFiles = [...this.state.demoFiles, { url: file.data }];
                                let files = file.nativeFile
                                this.setState({
                                    demoFiles: newFiles
                                });
                                Toast.loading('图片上传中', 0)
                                let formData = new FormData();
                                formData.append("file", files);
                                uploadImageReply(formData).then(res => {
                                    this.setState({
                                        urls: this.state.urls.concat(res.data)
                                    }, () => {
                                        Toast.hide()
                                    })
                                })
                            }}
                            onFileClick={
                                (e, file, i) => {
                                    this.setState({
                                        gallery: {
                                            url: file.url,
                                            id: i
                                        }
                                    })
                                }
                            }
                            lang={{
                                maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                            }}
                        />
                    </CellBody>
                </Cell>
            </div>
        );
    }
}

export default reply;