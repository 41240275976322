import React, { Component } from 'react'

export default class help extends Component {
    render() {
        return (
            <div style={{padding:'0 10px'}}>
               <div className="helpTitle">参与活动步骤说明</div>
                {/* <div className="help1">正在进行的冲100送20，冲200送50的优惠是蜂行钱包充值赠送的，钱包里的钱可以用于热水充值等。</div> */}
                <div className="help2">关注公众号：</div>
                    <div className="help3">蜂行校园，进入“蜂行校园生活服务平台”</div>
                <div className="help2">绑定学校：</div>
                    <div className="help3">首次进入的同学需要先选择学校，然后进行实名认证，在设置用于蜂行钱包的支付密码；</div>
                <div className="help2">钱包支付密码设置：</div>
                    <div className="help3">“我的”- 支付密码 - 支付密码验证 - 输入支付密码保存</div>
                <div className="help2">钱包充值：</div>
                    <div className="help3">“我的”- 热水余额、校园网余额 - 钱包充值</div>
                <div className="help2">领款金额设置：</div>
                    <div className="help3">领款机默认领款金额为20元，如果不想改变，此步骤可以省略；如果你想每次多领，可进行设置：“首页”- 热水自助系统 - 设置 - 选择金额保存</div>
                <div className="help2">热水实名：</div>
                    <div className="help3">如果首次进入没有进行实名认证，会在热水卡绑定之前提醒实名认证</div>
                <div className="help2">热水绑卡：</div>
                    <div className="help3">“我的”- 热水自助系统 - 只有在实名认证后才能进行绑卡</div>
                <div className="help2">热水卡充值：</div>
                    <div className="help3">“我的”- 热水自助系统 - 充值</div>
                    <div className="help3">蜂行钱包：直接输入钱包支付密码，充值金额默认为设置的领款金额</div>
                    <div className="help3">微信：选择金额支付即可</div>
            </div>
        )
    }
}
