import React, { Component } from 'react'
import { TabBar } from 'antd-mobile'

const aaa = {
    background:'red'
}

class Tabbar extends Component {
    state = {
        selectedTab: 'yellowTab',
        hidden: false,
    }
    renderContent(pageText) {
        return (
            <div style={{ backgroundColor: 'red', height: '100%', textAlign: 'center',color:'red' }} />
        );
    }
    componentWillMount() {
        let path = this.props.match.path
        if (path === '/') {
            this.setState({
                selectedTab: 'yellowTab',
            });
        } else if (path === '/car') {
            this.setState({
                selectedTab: 'redTab',
            });
        } else if (path === '/commission') {
            this.setState({
                selectedTab: 'blueTab',
            });
        } else {
            this.setState({
                selectedTab: 'greenTab',
            });
        }
    }
    showView(path) {
        this.props.history.push(path)
    }
    render() {
        return (
            <div style={{ position: 'fixed', bottom: '0', width: '100%' }}>
                <TabBar
                    unselectedTintColor="#929292"
                    tintColor="#313131"
                    barTintColor="#fff"
                    hidden={this.state.hidden}
                >
                    <TabBar.Item
                        style={aaa}
                        title="首页"
                        key="home"
                        icon={<div style={{
                            width: '22px',
                            height: '22px',
                            background: 'url(' + require("../image/首页-未点击.png") + ') center center /  21px 21px no-repeat'
                        }}
                        />
                        }
                        selectedIcon={<div style={{
                            width: '22px',
                            height: '22px',
                            background: 'url(' + require("../image/首页-点击后.png") + ') center center /  21px 21px no-repeat'
                        }}
                        />
                        }
                        selected={this.state.selectedTab === 'yellowTab'}
                        onPress={() => {
                            this.showView('/')
                        }}
                        data-seed="logId" 
                    >
                        {this.renderContent('首页')}
                    </TabBar.Item>
                    <TabBar.Item
                        title="购物车"
                        key="home"
                        icon={<div style={{
                            width: '22px',
                            height: '22px',
                            background: 'url(' + require("../image/购物车-未点击.png") + ') center center /  21px 21px no-repeat'
                        }}
                        />
                        }
                        selectedIcon={<div style={{
                            width: '22px',
                            height: '22px',
                            background: 'url(' + require("../image/购物车-未点击.png") + ') center center /  21px 21px no-repeat'
                        }}
                        />
                        }
                        selected={this.state.selectedTab === 'redTab'}
                        onPress={() => {
                            this.showView('/car')
                        }}
                        data-seed="logId1"
                    >
                        {this.renderContent('购物车')}
                    </TabBar.Item>
                    <TabBar.Item
                        title="待办项"
                        key="home"
                        icon={<div style={{
                            width: '22px',
                            height: '22px',
                            background: 'url(' + require("../image/待办项-未点击.png") + ') center center /  21px 21px no-repeat'
                        }}
                        />
                        }
                        selectedIcon={<div style={{
                            width: '22px',
                            height: '22px',
                            background: 'url(' + require("../image/待办项-点击.png") + ') center center /  21px 21px no-repeat'
                        }}
                        />
                        }
                        selected={this.state.selectedTab === 'blueTab'}
                        onPress={() => {
                            this.showView('/commission')
                        }}
                        data-seed="logId2"
                    >
                        {this.renderContent('待办项')}
                    </TabBar.Item>
                    <TabBar.Item
                        icon={
                            <div style={{
                                width: '22px',
                                height: '22px',
                                background: 'url(' + require("../image/我的-未点击.png") + ') center center /  21px 21px no-repeat'
                            }}
                            />
                        }
                        selectedIcon={
                            <div style={{
                                width: '22px',
                                height: '22px',
                                background: 'url(' + require("../image/我的-点击.png") + ') center center /  21px 21px no-repeat'
                            }}
                            />
                        }
                        title="我的"
                        key="my"
                        selected={this.state.selectedTab === 'greenTab'}
                        onPress={() => {
                            this.showView('/my')
                        }}
                        data-seed="logId3"
                    >
                        {this.renderContent('my')}
                    </TabBar.Item>
                </TabBar>
            </div>
        )
    }
}

export default Tabbar;