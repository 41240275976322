import React, { Component } from 'react'
import { Tabs, Modal, InputItem, Toast } from 'antd-mobile'
import { createForm } from 'rc-form';
import { findAppOrderList, updateOrderListStatus } from '../../api/my'
import IP from '../../config/config'
import './index.css'

class NewRegistration extends Component {
  state = {
    index: '1',
    visible: false,
    data: [],
    listId: 0,
    ssPhone: '',
  }
  componentDidMount = () => {
    this.fetchData(1)
  }

  // 获取数据
  fetchData = async (flag, phone) => {
    const res = await findAppOrderList({ flag, phone })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // Tab页发生改变的时候
  handleTabChange = (tab, index) => {
    this.fetchData(tab.sub)
    this.setState({ index: tab.sub, ssPhone: '' });
  }

  // 订单办理
  transaction = async (listId, telephone) => {
    const { ssPhone } = this.state;
    const res = await updateOrderListStatus({ listId, telephone, flag: 2, type: 1 })
    if (res.code === 20000) {
      Toast.info(res.message, 2);
      this.fetchData(1, ssPhone)
    }
  }

  // 新卡号弹框console.log()
  handleTransaction = (flag, listId, phone) => {
    this.props.form.setFieldsValue({ phone });
    if (flag === 2 ||flag === 3) {
      this.transaction(listId)
    }
    if (flag === 1 ) {
      this.setState({
        visible: true,
        listId
      })
    }
  }

  // 搜索手机号
  handleChangePhone = e => {
    console.log(e)
    const { index } = this.state;
    console.log(index, 'sssssssss')
    this.setState({
      ssPhone: e
    })
    this.fetchData(index, e)
  }

  render() {
    const { getFieldProps } = this.props.form;
    const { visible, data, listId, ssPhone } = this.state;
    const tabs = [
      { title: '未办理', sub: '1' },
      { title: '已办理', sub: '2' },
    ];
    return (
      <div>
        <div className='newRegistration-input'>
          <InputItem placeholder="请输入手机号" value={ssPhone} onChange={val => this.handleChangePhone(val)} clear />
        </div>
        <div className='indent'>
          <Tabs tabs={tabs}
            initialPage={0}
            onChange={(tab, index) => this.handleTabChange(tab, index)}
          >
            <div>
              {
                data && data.length ? data.map(item => {
                  return <div className='indent_list' key={item.order_id}>
                    <div className='indent_list_title'>订单编号：{item.order_number}</div>
                    <div>联系方式：{item.phone}</div>
                    <div className='indent_list_item'>
                      <div className='indent_list_item_title'>
                        <span>{item.goods_name}</span>
                        <span>交易成功</span>
                      </div>
                      {
                        item.orientationOrderListEntities && item.orientationOrderListEntities.length && item.orientationOrderListEntities.map(o => {
                          return <div className='indent_list_item_info' key={o.list_id}>
                            <div className='indent_info_img'>
                              {
                                o.item_image ? <img src={IP.host + o.item_image} /> :
                                  <img src={require('../../image/02.png')} />
                              }
                            </div>
                            <div className='indent_info_content'>
                              <div>名称：{o.item_name}</div>
                              <div>状态：
                              {
                                  o.status === 1 && <span className='indent_info_content_state2'>已办理</span>
                                }
                                {
                                  o.status === 2 && <span className='indent_info_content_state1'>未办理</span>
                                }
                              </div>
                              <div>数量：1</div>
                              {
                                o.telephone && <div>新卡号：{o.telephone}</div>
                              }
                            </div>
                            {
                              o.status === 2 && <div className='transaction' onClick={() => this.handleTransaction(o.item_state, o.list_id, o.telephone)}>办理</div>
                            }
                          </div>
                        })
                      }
                    </div>
                    <div className='indent_list_price'>合计：
                    <span className="totalComeTo">
                        <span className="flag">￥</span>
                        {
                          (/^\d+$/.test(String(item.goods_price).split('.'))) ?
                            (
                              <span className="int">{String(item.goods_price).split('.')}</span>
                            ) : (
                              <span>
                                <span className="int">{String(item.goods_price).split('.')[0]}</span>
                                <span className="decimals">{'.' + String(item.goods_price).split('.')[1]}</span>
                              </span>
                            )
                        }
                      </span>
                    </div>
                  </div>
                }) : <div style={{ fontSize: 18, textAlign: 'center', marginTop: 100, color: '#3c3c3c' }}>暂无订单</div>
              }
            </div>

            <div>
              {
                data && data.length ? data.map(item => {
                  return <div className='indent_list' key={item.order_id}>
                    <div className='indent_list_title'>订单编号：{item.order_number}</div>
                    <div>联系方式：{item.phone}</div>
                    <div className='indent_list_item'>
                      <div className='indent_list_item_title'>
                        <span>{item.goods_name}</span>
                        <span>交易成功</span>
                      </div>
                      {
                        item.orientationOrderListEntities && item.orientationOrderListEntities.length && item.orientationOrderListEntities.map(o => {
                          return <div className='indent_list_item_info' key={o.list_id}>
                            <div className='indent_info_img'>
                              {
                                o.item_image ? <img src={IP.host + o.item_image} /> :
                                  <img src={require('../../image/02.png')} />
                              }
                            </div>
                            <div className='indent_info_content'>
                              <div>名称：{o.item_name}</div>
                              <div>状态：<span className='indent_info_content_state2'>已办理</span></div>
                              <div>数量：1</div>
                              {
                                o.telephone && <div>新卡号：{o.telephone}</div>
                              }
                            </div>
                          </div>
                        })
                      }
                    </div>
                    <div className='indent_list_price'>合计：
                    <span className="totalComeTo">
                        <span className="flag">￥</span>
                        {
                          (/^\d+$/.test(String(item.goods_price).split('.'))) ?
                            (
                              <span className="int">{String(item.goods_price).split('.')}</span>
                            ) : (
                              <span>
                                <span className="int">{String(item.goods_price).split('.')[0]}</span>
                                <span className="decimals">{'.' + String(item.goods_price).split('.')[1]}</span>
                              </span>
                            )
                        }
                      </span>
                    </div>
                  </div>
                }) : <div style={{ fontSize: 18, textAlign: 'center', marginTop: 100, color: '#3c3c3c' }}>暂无订单</div>
              }
            </div>
          </Tabs>
          <Modal
            visible={visible}
            transparent
            title={<div className='indent_modal_title'><img src={require('../../image/1.0.png')} /><span>请输入新卡号</span></div>}
            footer={[
              { text: <div className='indent_modal_cancel'>取消</div>, onPress: () => { this.setState({ visible: false }) } },
              {
                text: <div className='indent_modal_ok'>确定</div>, onPress: () => {
                  if (this.props.form.getFieldsValue().phone) {
                    this.transaction(listId, this.props.form.getFieldsValue().phone)
                    this.setState({
                      visible: false
                    })
                  } else {
                    Toast.info('请输入新卡号', 2)
                  }
                }
              }
            ]}
          >
            <div className='indent_modal'>
              <InputItem
                {...getFieldProps('phone', {
                  normalize: (v, prev) => {
                    if (v && !/^[0-9]{0,11}$/.test(v)) {
                      return prev;
                    }
                    return v;
                  },
                })}
                placeholder="11位数字"
                clear />
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

const IndentInputExampleWrapper = createForm()(NewRegistration);
export default IndentInputExampleWrapper
