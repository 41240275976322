import React, { Component } from 'react'
import Tabbar from '../../components/tabbar'
import { Link } from 'react-router-dom'
import cookie from 'react-cookies'
import { req_searchPerson, req_updatePhone, req_updateCode, req_addFeeback, req_saveAdress, req_repairArea, req_findWalletByUserId, req_checkPayPasswordSMS, req_sendPayPasswordSMS, req_setPayPassWord } from '../../api/my'
import { Toast, Modal, WhiteSpace,Flex, Picker, Icon} from 'antd-mobile'
import './index.css'
import Axios from 'axios'

const alert = Modal.alert
export default class index extends Component {

    state = {
        user: {},
        phoneState: false,      // 修改手机号弹框
        phoneNum: '',        // 输入的手机号
        code: '',        // 手机验证码
        codeTime: 60,       // 手机验证码刷新时间
        isClick: false,       // 是否能点击获取去验证码
        show: false,     // 建议反馈弹框
        advice: [
            { title: '界面错误', value: '1' },
            { title: '内容错误', value: '2' },
            { title: '程序错误', value: '3' },
            { title: '其他建议', value: '4' },
        ],
        shuju:[],
        adressVisible: false,    // 位置信息弹框
        data: '',
        room: '',    // 房间号
        buildingData: [],       // 
        buildingId: [],      // 位置id
        water_wallet: '',    // 热水余额
        net_wallet: '',      // 校园网余额
        buildingName: '',     // 宿舍位置
        passWord: false,         // 支付验证显示、隐藏
        phone: '',           // 设置支付时的验证手机号
        SMScode: '',         // 设置支付时的验证码
        isPayClick: false,   // 设置支付时获取验证码按钮是否可点击
        payPassWord: false,  // 支付密码显示隐藏
        payPaw: '',          // 支付密码
    }

    componentDidMount = async () => {
        await this.getUser()
        await this.searchArea()
        await this.getMoney()
        await this.task()
    }

    // 获取待办任务
    task = () =>{
        const that = this
        Axios.get(`http://fxxy.xctdmall.cn:8674/rechargelistapp/countOrder?schoolId=${cookie.load("data").school_id}`).then(function (response) {
            if(response.data.code===20000){
                that.setState({
                    shuju:response.data.data
                })
            }else{
                Toast.fail(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    // 获取用户信息
    getUser = () => {
        return req_searchPerson({ userId: cookie.load("userId") }).then(res => {
            if (res.code === 20000) {
                cookie.save('data', res.data)
                this.setState({
                    user: res.data,
                    phoneNum: res.data.user_phone,
                    buildingId: res.data.area_id ? res.data.area_id.split(",").map(x => Number(x)) : [],
                    room: res.data.room,
                    buildingName: res.data.area_name,
                    phone: res.data.user_phone
                })
                if (this.props.location.search && !this.state.user.pay_password) {
                    alert('设置密码', '请及时设置用于蜂行钱包的支付密码', [
                        { text: '取消' }, {
                            text: '确定', color: '#222', onPress: () => {
                                this.setState({
                                    passWord: true
                                })
                            }
                        }
                    ])
                }
            } else {
                Toast.fail(res.message)
            }

        })
    }

    // 获取钱包余额
    getMoney = () => {
        const { user } = this.state
        let obj = {
            schoolId: user.school_id,
            userId: user.user_id,
        }
        return req_findWalletByUserId(obj).then(res => {
            if (res.code === 20000) {
                res.data.map(x => {
                    if (x.wallet_type === 0) {
                        this.setState({
                            water_wallet: x.wallet_total_money
                        })
                    } else {
                        this.setState({
                            net_wallet: x.wallet_total_money
                        })
                    }
                    return x;
                })
            }
        })
    }

    // 验证码按钮在60s后可重新点击
    codeTime = () => {
        let that = this
        let time = 60
        var times = setInterval(() => {
            time = time - 1
            this.setState({
                codeTime: time
            }, () => {
                if (this.state.codeTime === 0) {
                    that.setState({
                        isClick: false,
                        codeTime: 60,
                        isPayClick: false
                    })
                    clearInterval(times)
                }
            })

        }, 1000)
    }

    // 判断数组层级
    getMaxFloor(treeData) {
        let max = 0
        function each(data, floor) {
            data.forEach(e => {
                if (floor > max) {
                    max = floor
                }
                if (e.children.length > 0) {
                    each(e.children, floor + 1)
                }
            })
        }
        each(treeData, 1)
        return max
    }

    // 点击位置是获取位置信息
    searchArea = () => {
        const { user } = this.state
        return req_repairArea({ school_id: user.school_id }).then(res => {
            let array = res.data.map(x => { return { label: x.area_name, value: x.area_id, parentid: x.parent_id } });
            let newArray = array.map(p => {
                p.children = array.filter(x => x.parentid === p.value)
                return p;
            })
            let secondParentId = newArray.find(y => y.parentid === 0).value
            newArray = newArray.filter(x => x.parentid === secondParentId)
            this.setState({
                building: newArray,
                buildingData: res.data
            }, () => {
                this.setState({
                    count: this.getMaxFloor(newArray)
                })
            })
        })
    }

    render() {
        const { shuju, user, phoneState, phoneNum, adressVisible, building, buildingId, count, room, water_wallet, net_wallet, buildingName, passWord, phone, isPayClick, payPassWord, payPaw } = this.state
        return (
            <div className="my">
                <div className="header">
                    <img src={require('../../image/我的顶部背景.png')} alt="" style={{ width: '100%' }} />
                    <div className="headerTop">
                        <span><img src={user.user_avatar} alt="" />{user.user_nick}</span>
                        <span className="headerRight" onClick={() => {
                            this.props.history.push('/recharge')
                        }}>充值明细  </span>
                    </div>
                </div>
                {/* 积分、余额 */}
                <div className="top">
                    <div className="topFlex clearfloat">
                        <Link to="/pay/1">
                            <div className="topLeft">
                                <span>{net_wallet ? net_wallet : 0.00}</span>
                                <p>校园网余额</p>
                            </div>
                        </Link>
                        <Link to="/pay/0">
                            <div className="topRight">
                                <span>{water_wallet ? water_wallet : 0.00}</span>
                                <p>热水余额</p>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* 模块 */}
                <div className="module">
                    <ul>
                        <li onClick={() => {
                            this.setState({
                                phoneState: true
                            })
                        }}>
                            <img src={require('../../image/手机号.png')} className='moduleIcon' alt="" />
                            <span>手机号</span>
                        </li>
                        <li onClick={() => {
                            this.setState({
                                adressVisible: true
                            })
                        }}>
                            <img src={require('../../image/收货地址.png')} className='moduleIcon' alt="" />
                            <span>宿舍地址</span>
                        </li>
                        <li onClick={() => {
                            this.setState({
                                passWord: true,
                                phone: user.user_phone
                            })
                        }}>
                            <img src={require('../../image/支付密码.png')} className='moduleIcon' alt="" />
                            <span>支付密码</span>
                        </li>
                        <li onClick={() => {
                            this.setState({
                                show: true
                            })
                        }}>
                            <img src={require('../../image/建议反馈.png')} className='moduleIcon' alt="" />
                            <span>建议反馈</span>
                        </li>
                        <li onClick={() => {
                            this.props.history.push('/realName')
                        }}>
                            <img src={require('../../image/实名认证.png')} className='moduleIcon' alt="" />
                            <span>实名认证</span>
                        </li>
                        <li onClick={() => {
                            this.props.history.push('/help')
                        }}>
                            <img src={require('../../image/帮助文档.png')} className='moduleIcon' alt="" />
                            <span>帮助文档</span>
                        </li>
                        <li onClick={() => {
                            window.location.href = `http://fxxy.xctdmall.cn:3000/myIndent?sessionId=${cookie.load('sessionId')}`
                        }}>
                            <img src={require('../../image/迎新订单.png')} className='moduleIcon' alt="" />
                            <span>我的订单</span>
                        </li>
                        {!!cookie.load("data").export_role_id&&<li onClick={() => {
                            this.props.history.push("/moive")
                        }}>
                            <img src={require('../../image/moive.png')} className='moduleIcon' alt="" />
                            <span>电影影讯</span>
                        </li>}
                        {!!cookie.load("data").multimedia_flag&&<li onClick={() => {
                            window.location.href = `http://fxxy.xctdmall.cn:6677?sessionId=${cookie.load('sessionId')}`
                        }}>
                            <img src={require('../../image/media.png')} className='moduleIcon' alt="" />
                            <span>多媒体管理</span>
                        </li>}
                        {!!cookie.load("data").campus_role&&<li onClick={() => {
                            window.location.href = `http://fxxy.xctdmall.cn:6421?sessionId=${cookie.load('sessionId')}&actionCard=100`
                        }}>
                            <img src={require('../../image/100.png')} className='moduleIcon' alt="" />
                            <span>预约办卡</span>
                            <span className="orderNumber">{shuju[0]&&String(shuju[0]).length>3?'99+':shuju[0]}</span>
                        </li>}
                        {!!cookie.load("data").account_role&&<li onClick={() => {
                            window.location.href = `http://fxxy.xctdmall.cn:6421?sessionId=${cookie.load('sessionId')}&openAccount=100`
                        }}>
                            <img src={require('../../image/101.png')} className='moduleIcon' alt="" />
                            <span>办理开户</span>
                            <span className="orderNumber">{shuju[1]&&String(shuju[1]).length>3?'99+':shuju[1]}</span>
                        </li>}
                        {!!cookie.load("data").kuandai_state&&<li onClick={() => {
                            this.props.history.push("/broadband")
                        }}>
                            <img src={require('../../image/102.png')} className='moduleIcon' alt="" />
                            <span>宽带办理</span>
                        </li>}
                        {!!cookie.load("data").orientation_state&&<li onClick={() => {
                            this.props.history.push("/newRegistration")
                        }}>
                            <img src={require('../../image/yingxin.png')} className='moduleIcon' alt="" />
                            <span>迎新办理</span>
                        </li>}
                        {/* {<li onClick={() => {
                            this.props.history.push("/newRegistration")
                        }}>
                            <img src={require('../../image/yingxin.png')} className='moduleIcon' alt="" />
                            <span>迎新办理</span>
                        </li>} */}
                    </ul>
                </div>
                <Tabbar {...this.props}></Tabbar>
                {/* 修改手机号 transparent圆角 */}
                <Modal style={{ width: '85%'}} transparent={true} closable={true} visible={phoneState} title={<span className="modalTltle">手机号修改</span>} onClose={() => { this.setState({ phoneState: false }) }}>
                    <div className="modalPad">
                        <input onBlur={()=>{
                            document.body.scrollTop = 0
                        }} type="number" placeholder={phoneNum ? phoneNum : '请输入手机号'} onChange={(v) => {
                            this.setState({
                                phoneNum: v.target.value,
                            })
                        }} className="modalInput" />
                    </div>
                    <div className="flexModal">
                        <div style={{marginRight:10,flex:3}}>
                            <input onBlur={()=>{
                                document.body.scrollTop = 0
                                }} type="number" placeholder='输入验证码' onChange={(v) => {
                                this.setState({
                                    code: v.target.value,
                                })
                            }} className="modalCode" />
                        </div>
                        <div className="buttonStyle" style={{flex:2}} onClick={() => {
                                if(this.state.isClick) return Toast.info("请在倒计时结束后点击")
                                if (!this.state.phoneNum || this.state.phoneNum.length !== 11) {
                                    Toast.fail('输入的新手机号不合法')
                                    return false;
                                }
                                req_updateCode({ phoneNumber: this.state.phoneNum, userId: this.state.user.user_id }).then(res => {
                                    Toast.info(res.message)
                                    if (res.code === 20000) {
                                        this.setState({
                                            isClick: true
                                        }, () => {
                                            this.codeTime()
                                        })
                                    }
                                })
                            }}>{this.state.isClick ? '倒计时'+this.state.codeTime + 's' : '获取验证码'}
                        </div>
                    </div>
                    <WhiteSpace />
                    <div className='flex'>
                        <div className="colse" onClick={() => {
                            this.setState({
                                phoneState: false
                            })
                        }}>取消</div>
                        <div className="ok" onClick={() => {
                            if (!this.state.phoneNum) {
                                Toast.fail('请填写手机号')
                                return false;
                            }
                            if (this.state.phoneNum.length !== 11) {
                                Toast.fail('输入的新手机号不合法')
                                return false;
                            }
                            if (!this.state.code) {
                                Toast.fail('请输入验证码')
                                return false;
                            }
                            let param = {
                                userId: this.state.user.user_id,
                                phoneNumber: this.state.phoneNum,
                                checkCode: this.state.code
                            }
                            req_updatePhone(param).then(res => {
                                if (res.code === 20000) {
                                    Toast.success('修改成功')
                                    this.setState({ phoneState: false })
                                    this.getUser();
                                } else {
                                    Toast.fail(res.message)
                                }
                            })
                        }}>确定</div>
                    </div>
                </Modal>
                {/* 建议反馈 */}
                <Modal
                    style={{ width: '85%' }} transparent={true}
                    closable={true}
                    visible={this.state.show}
                    title={<span className="modalTitle">建议反馈</span>}
                    onClose={() => { this.setState({ show: false }) }}
                >
                    <div style={{ padding: '0 20px' }}>
                        <Flex wrap="wrap" justify="start">
                            {this.state.advice.map(v => {
                                return (
                                    <Flex.Item key={v.value} style={{ flex: 'auto', marginLeft: 0 }}>
                                        <input type='radio' name='radioName' value={v.value} onChange={(e) => {
                                            this.setState({
                                                value: e.target.value
                                            })
                                        }} />
                                        {v.title}
                                    </Flex.Item>
                                )
                            })}
                        </Flex>
                        <WhiteSpace size="md" /><WhiteSpace size="md" /><WhiteSpace size="md" />
                        <textarea rows={4} placeholder='请输入你的建议' style={{ width: '100%', padding: '3px' }}></textarea>
                        <WhiteSpace size="md" />
                    </div>
                    <div className='flex'>
                        <div className="colse" onClick={() => {
                            this.setState({
                                show: false
                            })
                        }}>取消</div>
                        <div className="ok" onClick={() => {
                            let param = {
                                status: this.state.value,
                                text: document.getElementsByTagName('textarea')[0].value
                            }
                            if (!this.state.value) {
                                Toast.fail('请选择一个建议')
                                return false;
                            }
                            req_addFeeback(param).then(res => {
                                if (res.code === 20000) {
                                    Toast.success('谢谢你的建议')
                                    this.setState({
                                        value: ''
                                    })
                                } else {
                                    Toast.fail(res.message)
                                }
                            })
                            this.setState({
                                show: false
                            })
                        }}>提交</div>
                    </div>
                </Modal>
                {/* 位置信息 */}
                <Modal
                    style={{ width: '85%' }} transparent={true}
                    title={<span className="modalTitle">宿舍信息</span>}
                    visible={adressVisible}
                    onClose={() => this.setState({ adressVisible: false })}
                >
                    <div className="picker" >
                        <Picker
                            extra="请选择宿舍区域"
                            data={building}
                            value={buildingId}
                            cols={count}
                            onChange={(val) => {
                                this.setState({
                                    buildingId: val,
                                    buildingName: val.map(x => this.state.buildingData.find(p => p.area_id === x).area_name).join("")
                                })
                            }}
                        >
                            <div className="pickerSpan">{buildingName ? buildingName : '宿舍区域'} <Icon type="right" /> </div>
                        </Picker>
                    </div>
                    <div className="picker" style={{ border: '1px solid #eee' }}>
                        <input onBlur={()=>{
                            document.body.scrollTop = 0
                        }} placeholder={room ? room : '请填写房间号'} onChange={(e) => {
                            this.setState({
                                roomArea: e.target.value
                            })
                        }} />
                    </div>
                    <div className='flex'>
                        <div className="colse" onClick={() => {
                            this.setState({
                                adressVisible: false
                            })
                        }}>取消</div>
                        <div className="ok" onClick={() => {
                            req_saveAdress({ userId: this.state.user.user_id, area_id: this.state.buildingId.join(), area_name: this.state.buildingName, room: this.state.roomArea }).then(res => {
                                if (res.code === 20000) {
                                    Toast.success(res.message)
                                    this.getUser()
                                    this.setState({
                                        adressVisible: false
                                    })
                                } else {
                                    Toast.fail(res.message)
                                }
                            })
                        }}>确定</div>
                    </div>
                </Modal>
                {/* 支付密码验证 */}
                <Modal visible={passWord} title={<span className="modalTitle">支付密码验证</span>} style={{ width: '85%' }} transparent={true} closable={true} onClose={() => { this.setState({ passWord: false }) }}>
                    <div className="modalPad">
                        <input onBlur={()=>{
                            document.body.scrollTop = 0
                        }} type="number" disabled placeholder={phone ? phone : '请去实名页面添加手机号'} className="modalInput" />
                    </div>
                    <div className="flexModal">
                        <div style={{marginRight:10,flex:3}}>
                            <input onBlur={()=>{
                            document.body.scrollTop = 0
                        }} type="number" placeholder='请输入验证码' onChange={(v) => {
                                this.setState({
                                    SMScode: v.target.value,
                                })
                            }} className="modalCode" />
                        </div>
                        <div className="buttonStyle" style={{flex:2}} onClick={() => {
                            if(isPayClick) return Toast.info("请在倒计时结束后点击")
                                req_sendPayPasswordSMS({ userId: this.state.user.user_id }).then(res => {
                                    Toast.info(res.message)
                                    if (res.code === 20000) {
                                        this.setState({
                                            isPayClick: true
                                        }, () => {
                                            this.codeTime()
                                        })
                                    }
                                })
                            }}>{this.state.isPayClick ? "倒计时"+this.state.codeTime + 's' : '获取验证码'}
                        </div>
                    </div>
                    <WhiteSpace />
                    <div className='flex'>
                        <div className="colse" onClick={() => {
                            this.setState({
                                passWord: false
                            })
                        }}>取消</div>
                        <div className="ok" onClick={() => {
                            if (!this.state.SMScode) {
                                Toast.fail('请输入验证码')
                                return false;
                            }
                            req_checkPayPasswordSMS({ userId: user.user_id, SMScode: this.state.SMScode }).then(res => {
                                if (res.code === 20000) {
                                    this.setState({
                                        passWord: false,
                                        payPassWord: true
                                    })
                                } else {
                                    Toast.fail(res.message)
                                }
                            })
                        }}>验证</div>
                    </div>
                </Modal>
                {/* 设置支付密码 */}
                <Modal visible={payPassWord} title="支付密码" style={{ width: '85%'  }} transparent={true} closable={true} onClose={() => { this.setState({ payPassWord: false }) }}>
                    <div className="modalPad">
                        <input onBlur={()=>{
                            document.body.scrollTop = 0
                        }} type="password" placeholder="请输入6位数字密码" value={payPaw} onChange={(v) => {
                            if (v.target.value.replace(/^[^\s]*$/) !== 'undefined') {
                                v.target.value = ''
                                alert('不能输入空格');
                            }
                            var reg = /\D/
                            var result = reg.test(v.target.value)
                            if(result) return Toast.info("仅限输入数字",0.5)
                            if (v.target.value.length <= 6) {
                                this.setState({
                                    payPaw: v.target.value,
                                })
                            }
                        }} className="modalInput" />

                    </div>
                    <p style={{ padding: '10px 0 20px', color: '#e2e2e2', fontSize: '12px' }}>*该支付密码仅用于蜂行钱包</p>
                    <div className='flex'>
                        <div className="colse" onClick={() => {
                            this.setState({
                                payPassWord: false
                            })
                        }}>取消</div>
                        <div className="ok" onClick={() => {
                            if (this.state.payPaw.length !== 6) {
                                Toast.fail('密码长度为6')
                                return false;
                            }
                            req_setPayPassWord({ userId: user.user_id, payPassword: payPaw }).then(res => {
                                Toast.info(res.message)
                                if (res.code === 20000) {
                                    this.setState({
                                        payPassWord: false,
                                    })
                                }
                            })
                        }}>设置</div>
                    </div>
                </Modal>
            </div>
        )
    }
}
