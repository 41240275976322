import React, { Component } from 'react';
import { NavBar, Icon, Button, ImagePicker, Toast } from 'antd-mobile'
import { findPcRply, deleteAppReply } from '../../api'
import moment from 'moment';
import IP from '../../config/config';
import cookie from 'react-cookies';

class question extends Component {
    state = {
        problems: {}, // 问题
        questionData: [], // 回答
    }

    componentDidMount = () => {
        this.fetchDetail(this.props.match.params.id)
    }
    
    fetchDetail = async problemId => {
        const res = await findPcRply({ problemId, flag: 2, pageSize: 99999, pageNum: 1 })
        if (res.code === 20000) {
            this.setState({
                problems: res.data.problemEntity,
                questionData: res.data.problemReplyEntities.list
            })
        }
    }

    // 回复详情
    handleDetail = id => {
        this.props.history.push({pathname: `/problem/current/${id}`})
    }

    // 回答
    handleQuestion = id => {
        this.props.history.push({pathname: `/problem/reply/${id}`})
    }

    // 删除回复
    handleReplyDel = async (e, replyId) => {
        e.stopPropagation();  
        const res = await deleteAppReply({ replyId })
        if (res.code === 20000) {
            Toast.info('删除成功')
            this.fetchDetail(this.props.match.params.id)
        }
    }

    render() {
        const { problems, questionData } = this.state;
        return (
            <div className='question_home'>
                <NavBar
                    mode='mode'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    全部回复
                </NavBar>
                <div className='question_main'>
                    <div className='question_problem'>{problems.problem_title}</div>
                    <div className='qusetion_btn'>
                        <div>共{questionData && questionData.length}个回复</div>
                        <Button size="small" inline type='primary' onClick={() => this.handleQuestion(problems.problem_id)}>回复</Button>
                    </div>
                    <div className='question_separator'></div>
                    {
                        (questionData || []).map(item => {
                            return <div className='question_info' key={item.reply_id} onClick={() => this.handleDetail(item.reply_id)}>
                                <div className='problem_header'>
                                    <div className='problem_header_img'>
                                        <img src={item.reply_user_avatar} alt='' />
                                    </div>
                                    <div className='problem_header_name'>
                                        <div>{item.reply_user_nick}</div>
                                        <div>{moment(item.reply_time).format('YYYY-MM-DD')}</div>
                                    </div>
                                </div>
                                {
                                    (problems.problem_user_id === cookie.load('data').user_id) || (item.reply_user_id === cookie.load('data').user_id) ?
                                    <div className='problem_del question_del' onClick={(e) => this.handleReplyDel(e,item.reply_id)}>
                                        <div className="problem_del_img">
                                            <img src={require('./../../image/del.jpg')} alt="" />
                                        </div>
                                        {/* <div className="problem_del_text">
                                            删除
                                        </div> */}
                                    </div> : ''
                                }
                                
                                <div>{item.reply_content}</div>
                                {
                                    item.reply_images ?
                                    <ImagePicker
                                        files={item.reply_images.split(',').map((x,index)=>({id: index + 1, url: IP.host + x}))}
                                        disableDelete
                                        selectable={false}
                                    /> : ''
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }
}

export default question;