import React, { Component } from 'react'
import { Button, Toast, Picker, List } from 'antd-mobile'
import { modifypassword, senSmsCode } from '../../api/index'
import cookie from 'react-cookies';
import './mima.css'
const { Item } = List

export default class Mima extends Component {
  state = {
    accountNum: '',        // 账号
    password: '',        // 密码
    code: '',            // 手机验证码
    flag: [0],
    stateType: [
      {
        value: 1,
        label: '是'
      },
      {
        value: 0,
        label: '否'
      }
    ]
  }

  // 提交
  submit = () => {
    Toast.loading('提交中...', 0)
    const { accountNum, code, password, flag } = this.state
    if (accountNum.length !== 14) {
      Toast.fail('账号错误或非常规账号,请去校园网中心人工修改校园网账号密码!!')
      return
    }
    if (!code) {
      Toast.fail('请输入验证码');
      return;
    }
    if (!password) {
      Toast.fail('请输入新密码');
      return;
    }
    let userEntity = {
      campusAccount: accountNum,
      password,
      checkCode: code,
      flag:flag.join(',')
    }
    modifypassword(userEntity).then(res => {
      Toast.info(res.message)
      if (res.code === 20000) {
        this.props.history.push('/')
        Toast.hide()
      }
    })
  }

  render() {
    const { accountNum, password, flag } = this.state
    return (
      <div className="realName" style={{ paddingTop: 20 }}>
        <div className="flexPad">
          <div><i>*</i>校园网账号</div>
          <input type="text" placeholder={accountNum ? accountNum : '请输入校园网账号'} onChange={(v) => {
            this.setState({
              accountNum: v.target.value,
            })
          }} />
        </div>
        <div className="flexPad">
          <div><i>*</i>验证码</div>
          {this.state.isClick && <input type="number" placeholder='请输入验证码' onChange={(v) => {
            this.setState({
              code: v.target.value,
            })
          }} className="modalCode" />}
          {!this.state.isClick && <div className="buttonStyle1" onClick={() => {
            if (!this.state.accountNum || this.state.accountNum.length !== 14) {
              Toast.fail('账号错误或非常规账号,请去校园网中心人工修改校园网账号密码!!')
              return false;
            }
            senSmsCode({ userId: this.state.accountNum }).then(res => {
              Toast.info(res.message)
              if (res.code === 20000) {
                this.setState({
                  isClick: true,
                })
              }
            })
          }}>获取验证码</div>}
        </div>
        <div className="flexPad">
          <div><i>*</i>新密码</div>
          <input type="password" placeholder={password ? password : '请输入新密码'} onChange={(v) => {
            this.setState({
              password: v.target.value,
            })
          }} />
        </div>

        {
          cookie.load('data').school_id == 41 ? (<div className="flexPad">
            <div><i>*</i>是否在黄淮学院</div>
            <Picker data={this.state.stateType} cols={1} value={flag} onChange={(flag)=>{
              this.setState({
                flag
              })
            }}>
              <Item arrow="horizontal"  className="mima"></Item>
            </Picker>
          </div>) : ('')
        }

        <Button style={{ background: '#fc0', color: '#222', marginTop: '.8rem' }} onClick={() => this.submit()}>提交</Button>
      </div>
    )
  }
}
