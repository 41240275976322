import React, { Component } from 'react'
import cookie from 'react-cookies'
import { InputItem, Toast, Button, Modal } from 'antd-mobile'
import { req_checkPayPasswordSMS, req_setPayPassWord, req_sendPayPasswordSMS } from '../../api/my'
import './index.css'

export default class payPwd extends Component {
    state = {
        user: cookie.load('data'),    // 用户信息
        phone: cookie.load('data').user_phone || '', // 手机号
        codeTime: 60,    // 重新获取验证码时间
        visible: false,
        code: '',        // 验证码
        isClick: false,   // 获取验证码按钮是否可点击
        password: '',    // 支付密码
    }

    // 验证码按钮在60s后可重新点击
    codeTime = () => {
        let that = this
        let time = this.state.codeTime
        var times = setInterval(() => {
            time = time - 1
            this.setState({
                codeTime: time
            }, () => {
                if (this.state.codeTime === 0) {
                    that.setState({
                        isClick: false,
                        codeTime: 60
                    })
                    clearInterval(times)
                }
            })

        }, 1000)
    }

    render() {
        const { phone, visible, user, code, password } = this.state
        return (
            <div className='payPwd'>
                <InputItem type="phone" disabled placeholder={phone ? phone : '请去我的页面添加手机号'}
                    onChange={(v) => {
                        if (v.length === 13) {
                            this.setState({
                                phoneNum: v,
                            })
                        }
                    }}
                    style={{ border: "none", background: '#fff', width: '100%', height: "40px", lineHeight: '40px', padding: '0 20px', color: '#000' }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <InputItem
                        type="number"
                        placeholder='请输入验证码'
                        onChange={(v) => { this.setState({ code: v }) }}
                        style={{ border: 'none', height: "40px", lineHeight: '40px', paddingLeft: '20px', width: 130 }}
                    />
                    <Button type="ghost" disabled={this.state.isClick} size="small" style={{ marginTop: 5 }} onClick={() => {
                        req_sendPayPasswordSMS({ userId: this.state.user.user_id }).then(res => {
                            Toast.info(res.message)
                            if (res.code === 20000) {
                                this.setState({
                                    isClick: true
                                }, () => {
                                    this.codeTime()
                                })
                            }
                        })
                    }}>获取验证码{this.state.isClick ? this.state.codeTime + 's' : ''}</Button>
                </div>
                <Button style={{ background: '#fed509', color: '#fff' }} onClick={() => {
                    req_checkPayPasswordSMS({ userId: user.user_id, SMScode: code }).then(res => {
                        if (res.code === 20000) {
                            this.setState({
                                visible: true
                            })
                        } else {
                            Toast.fail(res.message)
                        }
                    })
                }}>验证</Button>

                {/* 设置的支付密码 */}
                <Modal visible={visible} title={<span style={{ fontSize: '24px', color: '#222' }}>设置支付密码</span>} onClose={() => { this.setState({ visible: false }) }} closable={true} animationType="slide-up">
                    <InputItem type="password" onBlur={()=>{
                            document.body.scrollTop = 0
                        }} placeholder="请输入6位数字密码" onChange={(v) => {
                        this.setState({
                            password: v
                        })
                    }}></InputItem>
                    <div className='flex'>
                        <div className="colse" onClick={() => {
                            this.setState({
                                visible: false
                            })
                        }}>取消</div>
                        <div className="ok" onClick={() => {
                            req_setPayPassWord({ userId: user.user_id, payPassword: password }).then(res => {
                                Toast.info(res.message)
                                if (res.code === 20000) {
                                    this.setState({
                                        visible: false
                                    })
                                    this.props.history.push('/my')
                                }
                            })
                        }}>设置</div>
                    </div>
                </Modal>
            </div>

        )
    }
}
