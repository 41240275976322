import http from '../config/http'

// 蜂行钱包充值
export const req_walletRecharge = params =>{
    return http.post('/fengxingwallet/walletRecharge',params)
}

// 获取签名信息
export const req_getSign = (params) => {
    return http.get('/wxapp/getSign', params)
}

// 用于判断登陆用户是否为第一次登录
export const req_judgeSchoolId = (params) => {
    return http.get('/repair/judgeSchoolId', params)
}

// 新用户登陆需要传递经度,纬度或者选择学校id
export const req_updateUser = (params) => {
    return http.get('/repair/updateUser', params)
}

// 微信手机端用户接入
export const req_getWXuserInfo = (params) => {
    return http.get('/wxapp/getWXuserInfo', params)
}

// 通过id查询个人信息
export const req_searchPerson = (params) => {
    return http.get('/sysuser/findUserEntityById', params)
}

// 修改用户信息手机号
export const req_updatePhone = (params) =>{
    return http.post('/sysuser/checkPhoneNumber',params)
}

// 获取手机验证码
export const req_updateCode = params =>{
    return http.get('/sysuser/sendPhoneCode',params)
}

// 我的页面建议反馈
export const req_addFeeback = params =>{
    return http.jsonPost('/repairfeedback/addFeeback',params)
}

// 我的保存位置信息
export const req_saveAdress = (params) => {
    return http.post('/sysuser/saveMine', params)
}

// 位置区域
export const req_repairArea = (params) => {
    return http.get('/repairarea/findRepairArea', params)
}

// 手机端查询钱包(0热水，1校园网)
export const req_findWalletByUserId = params =>{
    return http.get('/fengxingwallet/findWalletByUserId',params)
}

// 验证手机号，验证码
export const req_checkPayPasswordSMS = params =>{
    return http.post('/appwateroperation/checkPayPasswordSMS',params)
}

// 用户设置支付密码 
export const req_setPayPassWord = params =>{
    return http.post('/appwateroperation/setPayPassWord',params)
}

// 设置支付密码时，获取的验证码
export const req_sendPayPasswordSMS = params =>{
    return http.get('/appwateroperation/sendPayPasswordSMS',params)
}

// 用户实名认证
export const req_fengxingAuthentication = params =>{
    return http.jsonPost('sysuser/fengxingAuthentication',params)
}

// 充值记录
export const req_findWalletLogsByUserId = params =>{
    return http.get('/fengxingwalletlogs/findWalletLogsByUserId',params)
}

// 手机端钱包充值金额展示
export const req_appFindSaleMoney = params =>{
    return http.get('/fengxingwalletsalemoney/appFindSaleMoney',params)
}

// 发布电影资讯
export const addShadow = params => http.post('/shadowController/addShadow',params)

// 宽带办理

// 提交宽带办理手机号信息
export const saveManageRecord = params => http.post('kuandaimanage/saveManageRecord', params)

// 查询手机号
export const findManageRecord = params => http.get('/kuandaimanage/findManageRecord', params)

// 审核
export const updateManageRecord = params => http.post('/kuandaimanage/updateManageRecord', params)


/**
 * 手机端获取我的订单
 */
export const findAppOrderList = params => http.get('/orientationorderapp/findappOrder', params)

/**
 * 手机端对订单进行操作
 */
export const updateAppOrder = params => http.post('/orientationorderapp/updateAppOrder', params)

/**
 * 订单办理
 */
export const updateOrderListStatus = params => http.get('/orientationorderlist/updateOrderListStatus', params)


