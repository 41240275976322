import React, { Component } from 'react';
import cookie from 'react-cookies'
import { InputItem, Button, Tabs, Toast } from 'antd-mobile'
import { saveManageRecord, findManageRecord, updateManageRecord } from '../../api/my'
import moment from 'moment'

class Broadband extends Component {
  state = {
    kuandaiState: 1,
    kuandaiPhone: '',
    key: 1,
    phoneData: [],
    phoneData2: [],

  }

  componentDidMount = () => {
    console.log(cookie.load('data'))
    this.setState({
      kuandaiState: cookie.load('data').kuandai_state
    })
    this.handlePhone()
    this.handlePhone2()
  }

  handleSubmit = async () => {
    const { kuandaiPhone } = this.state;
    const newKuangdaiPhone = kuandaiPhone.replace(/\s+/g, "");
    if (!kuandaiPhone) return Toast.info('请填写手机号')
    console.log(newKuangdaiPhone)
    if (newKuangdaiPhone && (newKuangdaiPhone.length !== 11)) return Toast.info('请填写正确的手机号')
    const res = await saveManageRecord({ phoneNumber: kuandaiPhone })
    if (res.code === 20000) {
      this.setState({
        kuandaiPhone: ''
      })
      Toast.success(res.message)
    }
  }

  handleChange = key => {
    this.setState({
      key
    })
    if (key === 2) {
      this.handlePhone()
    }
  }

  handlePhone = async () => {
    const res = await findManageRecord({ flag: 1, pageSize: 9999, pageNum: 1 })
    console.log(res,'ddddddddddddddddddddddddddddddddddddddddddd')
    if (res.code === 20000) {
      this.setState({
        phoneData: res.data.list
      })
    }
  }

  handlePhone2 = async () => {
    const res = await findManageRecord({ flag: 2, pageSize: 9999, pageNum: 1 })
    if (res.code === 20000) {
      this.setState({
        phoneData2: res.data.list
      })
    }
  }

  handleShenhe = async id => {
    const res = await updateManageRecord({ recordId: id })
    if(res.code===20000){
      Toast.success('审核成功!')
      this.handlePhone2()
      this.handlePhone()
    }
  }

  render() {
    const { kuandaiState, kuandaiPhone, key, phoneData, phoneData2 } = this.state;
    const tabs = [
      { title: '待审核' },
      { title: '已审核' },
    ];
    return (
      <div className='broadband'>
        {
          kuandaiState === 1 && <div className='broadband-tijiao'>
            {
              key === 1 ? <div className='broadband-luru'>
                <InputItem value={kuandaiPhone} placeholder="请输入手机号" type='phone' clear onChange={e => this.setState({ kuandaiPhone: e })}></InputItem>
                <div className='tijiao-btn'>
                  <Button type='primary' onClick={() => this.handleSubmit()}>提交</Button>
                </div>
              </div> : <div className='broadband-my'>
                  {
                    (phoneData || []).map(item => {
                      return <div key={item.record_id} className='broadband-my-phone'><span>{item.phone_number}</span><span>{moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')}</span></div>
                    })
                  }
                </div>
            }
            <div className='broadband-btn'>
              <Button type='primary' onClick={() => this.handleChange(1)}>信息录入</Button>
              <Button type='primary' onClick={() => this.handleChange(2)}>我的</Button>
            </div>
          </div>
        }
        {
          kuandaiState === 2 && <div className='broadband-shenhe'>
            <Tabs tabs={tabs} initialPage={0}
            >
              <div>
                {
                  (phoneData2 || []).map(item => {
                    return <div className='shenhe' key={item.record_id}>
                      <div className='shenhe-content'>
                        <div className='shenhe-btn'>
                          <span>手机号：{item.phone_number}</span>
                          <span>时间：{moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')}</span>
                          <span>提交人：{item.create_user_name}</span>
                        </div>
                        <div className='btn'><Button type='primary' onClick={() => this.handleShenhe(item.record_id)}>审核</Button></div>
                      </div>
                    </div>
                  })
                }
              </div>
              <div>
                {
                  (phoneData || []).map(item => {
                    return <div className='shenhe' key={item.create_time}>
                      <div className='shenhe-content'>
                        <div className='shenhe-btn'>
                            <span>手机号：{item.phone_number}</span>
                            <span>提交时间：{moment(item.create_time).format('YYYY-MM-DD  HH:mm:ss')}</span>
                            <span>提交人：{item.manage_user_name}</span>
                            <span>审核时间：{moment(item.manage_time).format('YYYY-MM-DD  HH:mm:ss')}</span>
                        </div>
                      </div>
                    </div>
                  })
                }
              </div>
            </Tabs>
          </div>
        }
      </div>
    );
  }
}

export default Broadband;