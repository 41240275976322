import React, { Component } from 'react';
import { NavBar, Icon, Modal, List, InputItem, TextareaItem, Toast, Picker } from 'antd-mobile'
import { Gallery, GalleryDelete, Uploader, Cell, CellBody } from 'react-weui';
import { uploadImageReply, saveProblem, findAppProblemReply, deleteAppProblem, findAppProblemType } from '../../api'
import moment from 'moment';
import IP from '../../config/config'
import LazyLoad from 'react-lazyload';
import './index.css'

class index extends Component {
    state = {
        flag: 1,
        mine: false,
        visible: false,
        problemData: [], // 问答列表
        porblemTitle: '', // 问题
        problemDesc: '', // 答案
        urls: [],
        gallery: false,
        demoFiles: [],
        partData: [],
        pickData: [],
        typeId: 0,
    }

    componentDidMount = () => {
        this.fetch()
        this.searchPart()
    }

    // 查询问答列表
    fetch = async () => {
        const { typeId, flag } = this.state
        const res = await findAppProblemReply({ flag, typeId: typeId || undefined })
        if (res.code === 20000) {
            if(flag===2) this.setState({mine: true}) // 写在这里是因为点击"我的"时,如果没有数据时,删除图标依然会短暂显示,影响视觉
            this.setState({
                problemData: res.data
            })
        } else {
            Toast.fail(res.message)
            this.setState({
                problemData: []
            })
        }
    }

    // 查询版块
    searchPart = () => {
        findAppProblemType({ flag: 2 }).then(res => {
            if (res.code !== 20000) return Toast.fail(res.message)
            let arr = res.data.map(item => ({ ...item }))
            arr.unshift({ type_id: 0, type_name: '全部' })
            this.setState({
                partData: arr.map(x => ({
                    label: x.type_name,
                    value: x.type_id
                })),
                pickData: res.data.map(x => ({
                    label: x.type_name,
                    value: x.type_id
                }))
            })
        })
    }

    // react ui
    renderGallery = () => {
        const { gallery, demoFiles, urls } = this.state;
        if (!gallery) return false;
        let srcs = demoFiles.map(file => file.url)
        return (
            <Gallery
                src={srcs}
                show
                defaultIndex={gallery.id}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    this.setState({ gallery: false })
                }}
            >
                <GalleryDelete onClick={(e, id) => {
                    this.setState({
                        demoFiles: demoFiles.filter((e, i) => i !== id),
                        urls: urls.filter((e, i) => i !== id),
                        gallery: demoFiles.length <= 1 ? true : false
                    })
                }} />
            </Gallery>
        )
    }

    // 选择查看状态
    handleMine = (index) => {
        if (index === 1) {
            this.setState({
                mine: false,
                flag: 1
            }, () => {
                this.fetch()
            })
        }
        if (index === 2) {
            this.setState({
                
                flag: 2
            }, () => {
                this.fetch()
            })
        }

    }

    // 弹框
    handleModal = () => {
        this.setState({
            visible: true,
        }, () => {
            document.getElementsByClassName("weui-uploader__input")[0].removeAttribute("capture")
        })
    }

    // 发布问题
    handleIssue = async () => {
        const { porblemTitle, problemDesc, urls } = this.state;
        if (!porblemTitle||!this.state.pickValue) return Toast.info('请选择版块并输入标题');
        const problem = {
            type_id:this.state.pickValue.join(),
            problem_title: porblemTitle,
        }
        const reply = {
            reply_content: problemDesc,
            reply_images: urls && urls.length ? urls.join(',') : '',
        }
        const res = await saveProblem({ problem: JSON.stringify(problem), reply: JSON.stringify(reply) })
        if (res.code === 20000) {
            this.setState({ visible: false, urls: [], gallery: false, demoFiles: [], mine: false,pickValue:[] })
            Toast.info(res.message)
            this.fetch()
        }
    }

    // 查询问题下的所有答案
    handleAllQuestion = id => {
        this.props.history.push({ pathname: `/problem/question/${id}` })
    }

    // 查询问题下的当前答案
    handleCurrent = id => {
        this.props.history.push({ pathname: `/problem/current/${id}` })
    }

    // 删除自己的问题
    handleProblemDel = async problemId => {
        const res = await deleteAppProblem({ problemId })
        if (res.code === 20000) {
            Toast.info('删除成功')
            this.fetch()
            this.setState({
                mine: true
            })
        }
    }

    render() {
        const { mine, visible, problemData, partData, pickData } = this.state;
        return (
            <div className='problem_home'>
                <ul className="headline-ul">
                    {partData.map(x=><li className={this.state.typeId===x.value?'li-hover':''} key={x.value} onClick={()=>{
                        this.setState({
                            typeId:x.value
                        },()=>{
                            this.fetch()
                        })
                    }}>{x.label}</li>)}
                </ul>
                <div style={{paddingBottom:48}}/>
                <div className={`body-height ${problemData.length>0?"databody":''}`}>
                    {problemData.map(item=><div key={item.problem_id} className="content-main">
                        <div className="content-header">
                            <div className="content-header-main">
                                <div className="header-image">
                                    <img src={item.problem_user_avatar} alt='logo' />
                                </div>
                                <div className="header-message">
                                    <div className="header-message-nick">
                                        {item.problem_user_nick}
                                    </div>
                                    <div className="header-message-time">
                                        <span>{pickData.find(x=>x.value===item.type_id)&&pickData.find(x=>x.value===item.type_id).label}</span>
                                        <span style={{color:'#d7d7d7',padding:'0 5px'}}>|</span>
                                        <span>{moment(item.problem_create_time).format("YYYY-MM-DD HH:mm")}</span>
                                    </div>
                                </div>
                            </div>
                            {mine&&problemData.length>0&&<div className='problem_del' onClick={() => this.handleProblemDel(item.problem_id)}>
                                <div className="problem_del_img">
                                    <img src={require('./../../image/del.jpg')} alt="" />
                                </div>
                            </div>}
                        </div>
                        <div className="content-title" onClick={() => this.handleAllQuestion(item.problem_id)}>
                            {item.problem_title}
                        </div>
                        <div className="content" onClick={() => this.handleCurrent(item.reply_id)}>
                            {item.reply_content&&item.reply_content.length>50?item.reply_content.slice(0,50).concat("..."):item.reply_content}
                        </div>
                        {item.reply_images&&<div className="content-image" onClick={() => this.handleCurrent(item.reply_id)}>
                            {(item.reply_images.split(",").length>3?item.reply_images.split(",").slice(0,3):item.reply_images.split(",")).map(x=><div key={x}>
                                <LazyLoad throttle={200}><img style={{height:'100%',width:'100%'}} src={IP.host+x} alt="logo" /></LazyLoad>
                            </div>)}
                        </div>}
                    </div>)}
                </div>
                <div className='footer'>
                    <div className='footer_info'>
                        <div className='info_img' onClick={() => this.handleMine(1)}>
                            <img src={mine ? require('../../image/34.png') : require('../../image/33.png')} alt='' />
                            <span>首页</span>
                        </div>
                        <div className='info_img' onClick={() => this.handleMine(2)}>
                            <img src={mine ? require('../../image/35.png') : require('../../image/36.png')} alt='' />
                            <span>我的</span>
                        </div>
                    </div>
                    <div className='footer_add' onClick={this.handleModal}>
                        <img src={require('../../image/10.png')} alt='' />
                    </div>
                </div>
                <Modal
                    visible={visible}
                    transparent
                    className='search_modal'
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    {this.renderGallery()}
                    <NavBar
                        mode="mode"
                        icon={<Icon type="left" />}
                        onLeftClick={() => this.setState({ visible: false })}
                        rightContent={<div onClick={() => this.handleIssue()}>发布</div>}
                    >
                        发布信息
                    </NavBar>
                    <List>
                        {/* TODO */}
                        <Picker data={pickData} cols={1} value={this.state.pickValue} onOk={(e)=>{
                            this.setState({
                                pickValue:e
                            })
                        }}>
                            <List.Item arrow="horizontal">版块</List.Item>
                        </Picker>
                        <InputItem placeholder="请输入标题" style={{ textAlign: 'left' }} onChange={(e) => {
                            this.setState({
                                porblemTitle: e
                            })
                        }} />
                        <TextareaItem
                            placeholder="请输入发布的信息"
                            onChange={(e) => {
                                this.setState({
                                    problemDesc: e
                                })
                            }}
                            rows={5}
                            count={200}
                        />
                    </List>
                    <Cell className='problem_modal_img'>
                        <CellBody>
                            <Uploader
                                title='上传图片(选填)'
                                maxCount={4}
                                files={this.state.demoFiles}
                                onError={msg => alert(msg)}
                                onChange={(file) => {
                                    let newFiles = [...this.state.demoFiles, { url: file.data }];
                                    let files = file.nativeFile
                                    this.setState({
                                        demoFiles: newFiles
                                    });
                                    Toast.loading('图片上传中', 0)
                                    let formData = new FormData();
                                    formData.append("file", files);
                                    uploadImageReply(formData).then(res => {
                                        this.setState({
                                            urls: this.state.urls.concat(res.data)
                                        }, () => {
                                            Toast.hide()
                                        })
                                    })
                                }}
                                onFileClick={
                                    (e, file, i) => {
                                        this.setState({
                                            gallery: {
                                                url: file.url,
                                                id: i
                                            }
                                        })
                                    }
                                }
                                lang={{
                                    maxError: maxCount => `最多允许 ${maxCount} 张图片上传`
                                }}
                            />
                        </CellBody>
                    </Cell>
                </Modal>
            </div>
        );
    }
}

export default index;