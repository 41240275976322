import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Home from '../views/home/index';
import Car from '../views/car/index';
import Commission from '../views/commission/index'
import My from '../views/my/index'
// 充值页面
import Pay from '../views/my/pay'
// 文章详情页面
import Deatil from '../views/home/detail'
// 支付密码
import PayPwd from '../views/my/payPwd'
// 充值明细
import Recharge from '../views/my/recharge'
// 实名认证
import RealName from '../views/my/realName'
// 帮助文档
import Help from '../views/my/help'
// 电影影讯
import Moive from '../views/my/moive'
// 失物招领
import Problem from '../views/problem/index'
import Question from '../views/problem/question'
import Current from '../views/problem/current'
import Reply from '../views/problem/reply'
// 宽带办理
import Broadband from '../views/my/broadband'
// 迎新办理
import NewRegistration from '../views/my/newRegistration'
// 密码修改
import Mima from '../views/home/mima'
// 校园网下线
import schoolOut from '../views/home/school_out'


class RouteMap extends Component {
    render() {
        return (
            <div>
                <Route exact path="/" component={Home} />
                <Route exact path="/my" component={My} />
                <Route exact path="/car" component={Car} />
                <Route exact path="/commission" component={Commission} />
                {/* 充值 */}
                <Route exact path="/pay/:id" component={Pay} />
                {/* 文章详情 */}
                <Route exact path='/detail/:id' component={Deatil} />
                {/* 支付密码 */}
                <Route exact path='/payPwd' component={PayPwd} />
                {/* 充值明细 */}
                <Route exact path='/recharge' component={Recharge} />
                {/* 实名认证 */}
                <Route exact path='/realName' component={RealName} />
                {/* 帮助文档 */}
                <Route exact path='/help' component={Help} />
                {/* 电影影讯 */}
                <Route exact path='/moive' component={Moive} />
                {/* 宽带办理 */}
                <Route exact path='/broadband' component={Broadband} />
                {/* 迎新办理 */}
                <Route exact path='/newRegistration' component={NewRegistration} />
                {/* 密码修改 */}
                <Route exact path='/mima' component={Mima} />
                {/* 失物招领 */}
                <Route exact path="/problem/index" component={Problem} />
                <Route exact path="/problem/question/:id" component={Question} />
                <Route exact path="/problem/current/:id" component={Current} />
                <Route exact path="/problem/reply/:id" component={Reply} />
                <Route exact path="/schoolout/:id" component={schoolOut} />
            </div>
        )
    }
}

export default RouteMap